
import { ViewChildren, QueryList, Component, OnInit, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';

import * as R from 'ramda';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IUser } from 'src/app/interfaces/user';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { IAnnuaire, Annuaire } from 'src/app/interfaces/annuaire';
import { AnnuaireService } from 'src/app/providers/annuaire.service';
import { Router } from '@angular/router';
import { ModalComponent } from '../../modals/modal.component';
import { NgbHighlight, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, AsyncPipe } from '@angular/common';
import { NgbdSortableHeader as NgbdSortableHeader_1 } from '../../directives/sortable.directive';
import { FormsModule } from '@angular/forms';
import { ClientSelectComponent } from '../client-select/client-select.component';

@Component({
    selector: 'app-annuaire',
    templateUrl: './annuaire.component.html',
    styleUrls: ['./annuaire.component.scss'],
    standalone: true,
    imports: [ClientSelectComponent, NgxSpinnerModule, FormsModule, NgbdSortableHeader_1, NgClass, NgbHighlight, NgbPagination, ModalComponent, AsyncPipe]
})
export class AnnuaireComponent implements OnInit, OnDestroy {

  annuaires$: Observable<IAnnuaire[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;

  client: IUser = null;
  users: IUser[] = [];
  selectedAnnuaire: IAnnuaire = new Annuaire();

  clientSubscribe = null;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'company', 'service', 'lastname', 'firstname', 'faxnumber', 'telnumber', 'email' ];
  isAdmin = this.authService.isAdmin;

  showDeleteConfirm = false;
  showEditAnnuaire = false;
  initialized = false;

  constructor(
    public pagingService: PagingService<IAnnuaire>,
    private authService: AuthenticationService,
    private userService: UserService,
    private annuaireService: AnnuaireService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.annuaires$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;

  }

  ngOnInit(): void {
    this.pagingService.loadRows(of([]));
    console.log('annuaires onInit', this.client);
    this.clientSubscribe = this.userService.selectedUser$.subscribe(user => {
      if (user) {
        this.client = R.clone(user);
        if (this.client) {
          this.pagingService.loadRows(this.annuaireService.GetAll(this.client.id));
          this.loading$.subscribe(loading => {
            if (loading) {
              this.spinner.show();
            } else {
              this.spinner.hide();
              this.initialized = true;
            }
          });
        }
      }
    });
    setTimeout(() => {
      if (!this.client) {
        this.toastr.warning('Sélectionner un client en premier', '');
        this.router.navigate(['/']);
      }
    }, 1000);
  }

  ngOnDestroy() {
    if (this.clientSubscribe) {
      this.clientSubscribe.unsubscribe();
    }
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  Select(annuaire) {
    this.selectedAnnuaire = R.clone(annuaire);
  }

  Upload() {
    if (!this.client || this.client.id === 0) { return; }
  }

  Delete(annuaire) {
    this.selectedAnnuaire = annuaire;
    this.showDeleteConfirm = true;
  }

  DeleteConfirm() {
    this.showDeleteConfirm = false;
    this.spinner.show();

    this.annuaireService.Delete(this.client.id, this.selectedAnnuaire.id).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('Ce contact a été supprimé', '');
        this.pagingService.loadRows(this.annuaireService.GetAll(this.client.id));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.spinner.hide();
    });
  }

  addContact() {
    this.selectedAnnuaire = new Annuaire();
    this.showEditAnnuaire = true;
  }

  Edit(annuaire) {
    this.selectedAnnuaire = annuaire;
    this.showEditAnnuaire = true;
  }

  UpdateAnnuaire() {
    this.spinner.show();

    if (this.selectedAnnuaire.id > 0) {
      this.annuaireService.Update(this.client.id, this.selectedAnnuaire)
        .subscribe((response: any) => {
          if (response.success) {
            this.toastr.success(
              'Le contact a été modifiée'
            );
            this.pagingService.loadRows(this.annuaireService.GetAll(this.client.id));
          } else {
            this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
          }
          this.spinner.hide();
        });
    } else {
      this.annuaireService.Add(this.client.id, this.selectedAnnuaire)
        .subscribe((response: any) => {
          if (response.success) {
            this.toastr.success(
              'Le contact a été ajoutée'
            );
            this.pagingService.loadRows(this.annuaireService.GetAll(this.client.id));
          } else {
            this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
          }
          this.spinner.hide();
        });
    }
    this.showEditAnnuaire = false;
  }


}
