import { ViewChildren, QueryList, Component, OnInit, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router, ActivatedRoute } from '@angular/router';

import * as R from 'ramda';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbHighlight, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { IUser } from 'src/app/interfaces/user';
import { CampaignService } from 'src/app/providers/campaign.service';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { ContactListService } from 'src/app/providers/contact-list.service';
import { environment } from 'src/environments/environment';
import { ModalComponent } from '../../modals/modal.component';
import { NgbdSortableHeader as NgbdSortableHeader_1 } from '../../directives/sortable.directive';
import { FormsModule } from '@angular/forms';
import { NgClass, AsyncPipe } from '@angular/common';
import { ClientSelectComponent } from '../client-select/client-select.component';

@Component({
    selector: 'app-campaign-list',
    templateUrl: './campaign-list.component.html',
    styleUrls: ['./campaign-list.component.scss'],
    standalone: true,
    imports: [NgxSpinnerModule, ClientSelectComponent, NgClass, FormsModule, NgbdSortableHeader_1, NgbHighlight, NgbPagination, ModalComponent, AsyncPipe]
})
export class CampaignListComponent implements OnInit, OnDestroy {

  isAdmin = this.authService.isAdmin;

  campaigns$: Observable<any[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;

  clientSubscribe = null;
  reloadInterval = null;
  selectedCampaign: any;

  @Input() client: IUser;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'name', 'type', 'datemodification', 'total', 'messagelen'];

  showCancelConfirm = false;
  showDetailsModal = false;
  showMsgModal = false;
  showDeleteConfirm = false;
  modalHeaderText = '';
  modalBodyText = '';
  ModalConfirm = null;

  showAddModele = false;
  showResendDocument = false;
  phoneNumber: any;
  initialized = false;

  activeTab = 0;
  history: any;
  isLoading = false;


  constructor(
    public pagingService: PagingService<any>,
    private userService: UserService,
    private campaignService: CampaignService,
    private contactListService: ContactListService,
    private exportDataService: ExportDataService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private activateRoute: ActivatedRoute,
  ) {
    console.log('campaign ctor');
    this.campaigns$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;
    this.initialized = false;

    this.selectTabFromStatus(this.activatedRoute.snapshot.params.status);

    activateRoute.params.subscribe(params => {
      this.selectTabFromStatus(params.status);
    });
  }

  selectTabFromStatus(status) {
    switch (status) {
      case 'recus':
        this.activeTab = 1;
        break;
      case 'envoyes':
      default:
        this.activeTab = 0;
        break;
    }
    this.loadCampaigns();
  }

  ngOnInit(): void {

    console.log('campaign onInit', this.client);
    this.pagingService.loadRows(of([]));

    this.clientSubscribe = this.userService.selectedUser$.subscribe(user => {
      if (user) {
        this.client = R.clone(user);
        if (this.client) {
          this.pagingService.loadRows(this.campaignService.GetAll(this.client.id, this.activeTab));
          this.loading$.subscribe(loading => {
            if (loading) {
              this.spinner.show();
            } else if (!this.isLoading) {
              setTimeout(() => {
                this.spinner.hide();
                this.initialized = true;
              }, 600);
            }
          });
        }
      }
    });

    setTimeout(() => {
      if (!this.client) {
        this.toastr.warning('Sélectionner un client en premier', '');
        this.router.navigate(['/']);
      }
    }, 1000);

    this.reloadInterval = setInterval(() => {
      if (this.client && this.client.id !== 0) {
        this.pagingService.loadRows(this.campaignService.GetAll(this.client.id, this.activeTab));
      }
    }, 30000);
  }

  ngOnDestroy() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
    console.log('campaign list onDestroy');
    if (this.clientSubscribe) {
      this.clientSubscribe.unsubscribe();
    }
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  loadCampaigns() {
    if (this.client && this.client.id > 0) {
      this.spinner.show();
      this.initialized = false;
      this.pagingService.loadRows(of([]));
      this.pagingService.loadRows(this.campaignService.GetAll(this.client.id, this.activeTab));
    }
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  selectCampaign(campaign) {
    this.selectedCampaign = R.clone(campaign);
  }

  AddCampaign() {
    if (!this.client || this.client.id === 0) { return; }
    this.router.navigate(['/campagne/creation']);
  }

  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = ['id', 'name', 'type', 'datemodification', 'total', 'messagelen'];
    for (const u of this.pagingService.filteredRows) {
      const model = R.pick(fields, u);
      records.push(model);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'user');
  }

  Delete(campaign) {
    this.selectedCampaign = campaign;
    this.showDeleteConfirm = true;
    // console.log('show delete confirm');
  }

  DeleteConfirm() {
    this.showDeleteConfirm = false;
    this.spinner.show();
    this.campaignService.Delete(this.client.id, this.selectedCampaign.id).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('La campagne a été supprimée', '');
        this.pagingService.loadRows(this.campaignService.GetAll(this.client.id, this.activeTab));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.spinner.hide();
    });
  }

  Details(campaign) {
    this.selectedCampaign = R.clone(campaign);
    this.spinner.show();
    this.isLoading = true;
    this.showDetailsModal = true;
    this.campaignService.GetHistory(this.client.id, this.selectedCampaign.id)
    .subscribe((data: any) => {
      // console.log(data);
      if (data.success) {
        this.history = R.clone(data.history);
      } else {
        this.toastr.error(data.message, 'Erreur', { timeOut: 0 });
      }
      this.isLoading = false;
      this.spinner.hide();
    });
  }

  openPDF(campaign, direction) {
    let url = environment.apiUrl;
    if (window.location.origin.indexOf('fax.tims.fr') !== -1) {
      url = 'https://fax.tims.fr/api';
    } else if (window.location.origin.indexOf('fax.gedistelecom.com') !== -1) {
      url = 'https://fax.gedistelecom.com/api';
    }
    // vm.campaign = setFromId(id, tab);
    window.open(
      url + '/show_faxpdf.php?id=' + campaign.docid + '&docid=' + campaign.doc_docid,
      '',
      'toolbar=no, menubar=no,scrollbars=yes,resizable=yes,location=no,directories=no,status=no, width=1000, height=700');
  }


  Resend(campaign) {
    this.selectedCampaign = R.clone(campaign);
    this.campaignService.selectedCampaign = this.selectedCampaign;
    this.phoneNumber = JSON.parse(this.selectedCampaign.recipientsList);
    this.phoneNumber = this.phoneNumber.join(';');
    this.showResendDocument = true;
  }

  DoResend() {
    // resend campaign to new fax numbers

    const resendNumbers = this.phoneNumber.split(';');
    this.spinner.show();
    this.isLoading = true;

    this.campaignService.ResendCampaign(JSON.stringify(resendNumbers), this.selectedCampaign.id).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('La document va être envoyé', '');
        this.pagingService.loadRows(this.campaignService.GetAll(this.client.id, this.activeTab));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.showResendDocument = false;
      this.isLoading = false;
      this.spinner.hide();
    });
  }

}
