<div class="client-view p-md-4">
  <ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
    [fullScreen]="false">
  </ngx-spinner>

  <app-client-select [selectedUser]="client"></app-client-select>


  <div class="card">
    <div class="card-body">

      <div class="pull-right">
        <div class="btn btn-success" (click)="back()">Afficher les listes</div>
      </div>

      <ul class="nav nav-pills mb-n3" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': activeTab===0}" id="tab0" data-toggle="tab" (click)="selectTab(0)"
          role="tab" aria-controls="import" aria-selected="true">Importer</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': activeTab===1}" id="tab1" data-toggle="tab" (click)="selectTab(1)"
          role="tab" aria-controls="paste" aria-selected="false">Coller</a>
        </li>
      </ul>
      <hr>


        <div class="tab-content" id="myTabContent">
          @if (activeTab===0) {
            <div class="tab-pane fade show active" id="import" role="tabpanel" aria-labelledby="coord-tab"
              >
              <h3 class="bm-3">Importer une nouvelle liste</h3>
              <p>Vous pouvez importer des fichiers de type:</p>
              <ul>
                <li>CSV : fichiers texte dont les colonnes sont sépararées par une virgule, ou un point virgule</li>
                <li>Excel: au format .xls ou .xlsx</li>
              </ul>
              <p>Pensez à inclure une première ligne de texte pour nommer le contenu de chaque colonne</p>
              <br>
                <div class="form-inline">
                  <app-import-file [clientid]="client?.id"  [isSmall]="false"  (closeImport)="closeImport($event)" style="width:90%"></app-import-file>
                </div>
                <br>
                </div>
              }
            </div>

            <div class="tab-content" id="myTabContent">
              @if (activeTab===1) {
                <div class="tab-pane fade show active" id="coller" role="tabpanel" aria-labelledby="coord-tab"
                  >
                  <h2>Coller une nouvelle liste</h2>
                  <div style="height: 10px"></div>
                  <p>Vous pouvez coller des données copiées depuis Excel ou votre application:</p>
                  <ul>
                    <li>Les lignes doivent être séparées par des "Retour Chariot" ou "Retour à la ligne"</li>
                    <li>Les colonnes doivent être séparées par des "Tabulations"</li>
                  </ul>
                  <br><br>
                  <div class="pull-right btn btn-primary" (click)="clearInput()">Effacer tout</div>
                  <div class="h5">1-Collez vos données (CTRL+V)</div>
                  <div style="clear:both"></div>
                  <textarea name="paste" [(ngModel)]="rawdata" (ngModelChange)="parseRaw()"
                    placeholder="Copier ici vos données Excel ou applicatives" style="width:100%; height:160px">
                  </textarea>
                  <br>
                    <div class="h5">2-Renommez les colonnes et sélectionnez la colonne contenant les numéros de téléphone</div>
                    @if (parsedData?.length>0) {
                      <div style="max-height:200px; overflow-y:scroll">
                        <table class="table table-striped table-bordered">
                          <tr>
                            @for (col of parsedData[0]; track col; let i = $index) {
                              <th class="text-center" id="th{{i}}">
                                <div class="d-flex">
                                  <input style="font-size:10px; width:100%" type="text" name="col{{i}}" id="col{{i}}"
                                    [(ngModel)]="fields[i]">
                                  <div class="btn btn-sm btn-info" (click)="selectCol(i)" [ngClass]="{'btn-success': mobileCol==i}">
                                    @if (mobileCol==i) {
                                      <i class="fa fa-check"></i>
                                    }
                                    @if (mobileCol!=i) {
                                      <i>.</i>
                                    }
                                  </div>
                                </div>
                              </th>
                            }
                          </tr>
                          @for (row of parsedData; track row) {
                            <tr>
                              @for (col of row; track col) {
                                <td>{{col}}</td>
                              }
                            </tr>
                          }
                        </table>
                      </div>
                    }
                  </div>
                }

              </div>
            </div>

          </div>
        </div>

        <app-modal [(isActive)]="showAddList" [overlayDismiss]="true" (overlayClick)="showAddList=false" [isXLarge]="true">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Création d'une liste</h5>
              <div class="close" data-dismiss="modal" aria-label="Close" (click)="showAddList=false">
                <span aria-hidden="true">&times;</span>
              </div>
            </div>

            <div class="modal-body">
              <form role="form" name="form">
                <div class="col-xs-12">
                  <div class="h6">Nom des colonnes</div>
                  {{fields|json}}
                  <br>
                    <br>
                      <div class="form-group">
                        <label for="name" class="bold">Nom de la liste</label>
                        <input type="text" name="name" id="name" class="form-control" [(ngModel)]="listName" autocomplete="off"
                          autocapitalize="off" autocorrect="off" required>
                        @if (listName=='') {
                          <div class="text text-danger">Saisissez un nom</div>
                        }
                      </div>

                      <div class="clearfix" style="height: 20px"></div>
                      <div>{{parsedDataLength}} contact{{parsedDataLength>0 ? 's' : ''}} à importer</div>
                    </div>
                  </form>
                </div>

                <div class="modal-footer">
                  <button class="btn btn-primary" (click)='InsertContacts()' [disabled]="listName==''">Créer</button>
                  <div class="btn btn-secondary" data-dismiss="modal" (click)="showAddList=false">Annuler</div>
                </div>
              </div>
            </app-modal>

            <app-dialog [(isActive)]="dialog.show" [overlayDismiss]="true" (closeClick)="dialog.show=false; back()"
              [isLarge]="dialog.isLarge" [dialogTitle]="dialog.title" [dialogBody]="dialog.body" [dialogAction]="dialog.action"  [dialogType]="dialog.type">
            </app-dialog>

