@if (authService.isAdmin) {
  <div class="client-view">
    <!-- admin dashboard -->
    <div class="container scrollable">
      <p style="height: 5%">
      </p>
      <div class="row">
        <a class="col-12 col-md-4" (click)="openLinkNoCheck('/client/selection')">
          <div class="card-box tilebox-one menu-panel bg1">
            <i class="zmdi zmdi-accounts-outline left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Sélection client</h6>
          </div>
        </a>
        <div class="col-12 col-md-4" (click)="openLink('/client/lignesfax')">
          <div class="card-box tilebox-one menu-panel bg2">
            <i class="zmdi zmdi-accounts-list left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Lignes FAX</h6>
          </div>
        </div>
        <div class="col-12 col-md-4" (click)="openMenu(menu.list)">
          <div class="card-box tilebox-one menu-panel bg3">
            <i class="zmdi zmdi-info left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Contacts</h6>
          </div>
        </div>
      </div>
      @if (submenu===menu.list) {
        <div class="row text-center animate-if justify-content-center">
          <div class="col-4" (click)="openLink('/client/annuaire')">
            <div class="card-box tilebox-one menu-subpanel1">
              <h6 class="left-icon text-uppercase menu-subtitle">Annuaire</h6>
            </div>
          </div>
          <div class="col-4" (click)="openLink('/contact/list')">
            <div class="card-box tilebox-one menu-subpanel1">
              <h6 class="left-icon text-uppercase menu-subtitle">Gérer les listes</h6>
            </div>
          </div>
          <div class="col-4" (click)="openLink('/contact/charger')">
            <div class="card-box tilebox-one menu-subpanel1">
              <h6 class="left-icon text-uppercase menu-subtitle">Charger une liste</h6>
            </div>
          </div>
        </div>
      }
      <div class="row">
        <div class="col-12 col-md-4" (click)="openLink('/campagne/creation')">
          <div class="card-box tilebox-one menu-panel bg4">
            <i class="zmdi zmdi-plus-1 left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Envoyer Fax</h6>
          </div>
        </div>
        <div class="col-12 col-md-4" (click)="openLink('/fax/envoyes')">
          <div class="card-box tilebox-one menu-panel bg5">
            <i class="fa fa-sign-out left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Fax envoyés</h6>
          </div>
        </div>
        <div class="col-12 col-md-4" (click)="openLink('/fax/recus')">
          <div class="card-box tilebox-one menu-panel bg6">
            <i class="fa fa-sign-in left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Fax reçus</h6>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4" (click)="openLinkNoCheck('/credit/admin')">
          <div class="card-box tilebox-one menu-panel bg7">
            <i class="zmdi zmdi-trending-up left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Voir les consommations</h6>
          </div>
        </div>
        <div class="col-12 col-md-4" (click)="openLink('/client/credit')">
          <div class="card-box tilebox-one menu-panel bg8">
            <i class="zmdi zmdi-info left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Crédits client</h6>
          </div>
        </div>
        <div class="col-12 col-md-4" (click)="logout()">
          <div class="card-box tilebox-one menu-panel bg9">
            <i class="zmdi zmdi-power left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Déconnexion</h6>
          </div>
        </div>
      </div>
      <div class="row">
        @if (authService.adminRole==2) {
          <div class="col-12 col-md-4" (click)="openLinkNoCheck('/admin/manager')">
            <div class="card-box tilebox-one menu-panel bg8">
              <i class="zmdi zmdi-lock left-icon"></i>
              <h6 class="left-icon text-uppercase menu-title">Comptes Admin</h6>
            </div>
          </div>
        }
      </div>
    </div>
  </div>

} @else {

  <div class="client-view">
    <!-- client dashboard -->
    <div class="container scrollable">
      <p style="height: 5%">
      </p>
      <div class="row">
        <div class="col-12 col-md-4" (click)="openLink('/campagne/creation')">
          <div class="card-box tilebox-one menu-panel bg1">
            <i class="zmdi zmdi-plus-1 left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Envoyer Fax</h6>
          </div>
        </div>
        <div class="col-12 col-md-4" (click)="openLink('/fax/envoyes')">
          <div class="card-box tilebox-one menu-panel bg2">
            <i class="fa fa-sign-out left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Fax envoyés</h6>
          </div>
        </div>
        <div class="col-12 col-md-4" (click)="openLink('/fax/recus')">
          <div class="card-box tilebox-one menu-panel bg3">
            <i class="fa fa-sign-in left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Fax reçus</h6>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4" (click)="openMenu(menu.list)">
          <div class="card-box tilebox-one menu-panel bg4">
            <i class="zmdi zmdi-accounts-add left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Mes contacts</h6>
          </div>
        </div>
        <div class="col-12 col-md-4" (click)="openLinkNoCheck('/client/lignesfax')">
          <div class="card-box tilebox-one menu-panel bg5">
            <i class="zmdi zmdi-accounts-list left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Mes lignes FAX</h6>
          </div>
        </div>
        <div class="col-12 col-md-4" (click)="openLinkNoCheck('/client/edition')">
          <div class="card-box tilebox-one menu-panel bg6">
            <i class="zmdi zmdi-odnoklassniki left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Mes infos</h6>
          </div>
        </div>
      </div>
      @if (submenu===menu.list) {
        <div class="row text-center animate-if justify-content-center">
          <div class="col-4" (click)="openLinkNoCheck('/client/annuaire')">
            <div class="card-box tilebox-one menu-subpanel1">
              <h6 class="left-icon text-uppercase menu-subtitle">Annuaire</h6>
            </div>
          </div>
          <div class="col-4" (click)="openLinkNoCheck('/contact/list')">
            <div class="card-box tilebox-one menu-subpanel1">
              <h6 class="left-icon text-uppercase menu-subtitle">Gérer les listes</h6>
            </div>
          </div>
          <div class="col-4" (click)="openLinkNoCheck('/contact/charger')">
            <div class="card-box tilebox-one menu-subpanel1">
              <h6 class="left-icon text-uppercase menu-subtitle">Charger une liste</h6>
            </div>
          </div>
        </div>
      }
      <div class="row">
        <div class="col-12 col-md-4"  (click)="openLinkNoCheck('/client/credit')">
          <div class="card-box tilebox-one menu-panel bg7">
            <i class="zmdi zmdi-info left-icon"></i>
            <h6 class="left-icon text-uppercase menu-title">Mes crédits</h6>
          </div>
        </div>
        <!-- <div class="col-12 col-md-4" (click)="openLinkNoCheck('/client/achat')">
        <div class="card-box tilebox-one menu-panel bg8">
          <i class="zmdi  zmdi-shopping-cart-plus  left-icon"></i>
          <h6 class="left-icon text-uppercase menu-title">Commander<br/>des crédits</h6>
        </div>
      </div> -->
      <div class="col-12 col-md-4" (click)="openTutos()">
        <div class="card-box tilebox-one menu-panel bg8">
          <i class="zmdi zmdi-youtube-play left-icon"></i>
          <h6 class="left-icon text-uppercase menu-title">Tutos</h6>
        </div>
      </div>
      <div class="col-12 col-md-4" (click)="openLinkNoCheck('/nous_contacter')">
        <div class="card-box tilebox-one menu-panel bg9">
          <i class="zmdi zmdi-email left-icon"></i>
          <h6 class="left-icon text-uppercase menu-title">Nous contacter</h6>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4" (click)="logout()">
        <div class="card-box tilebox-one menu-panel bg8">
          <i class="zmdi zmdi-power left-icon"></i>
          <h6 class="left-icon text-uppercase menu-title">Déconnexion</h6>
        </div>
      </div>
    </div>
  </div>
</div>
}

<app-modal [(isActive)]="showLogoutConfirm" [overlayDismiss]="true" (overlayClick)="showLogoutConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Déconnexion ? </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showLogoutConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
      (click)="showLogoutConfirm=false">Annuler</button>
      <button type="button" class="btn btn-primary" (click)='logoutConfirm()'>Valider</button>
    </div>
  </div>
</app-modal>
