import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ClipboardService } from 'ngx-clipboard';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const TEXT_TYPE = 'application/txt';
const TEXT_EXTENSION = '.csv';


@Injectable({
  providedIn: 'root'
})
export class ExportDataService {


  constructor(
    private clipboardService: ClipboardService
  ) { }

  public exportAs(kind: string, json: any[], excelFileName: string = null) {
    if (kind === 'CSV') {
      this.exportAsCsvFile(json, excelFileName);
    } else if (kind === 'EXCEL') {
      this.exportAsExcelFile(json, excelFileName);
    } else if (kind === 'PRINT') {
      this.exportAsHtml(json);
    } else if (kind === 'TEXT') {
      this.exportAsText(json);
    }
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    // console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  public exportAsCsvFile(json: any[], csvFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    const csv = XLSX.utils.sheet_to_csv(worksheet);
    this.saveAsCsvFile(csv, csvFileName);
  }

  private saveAsCsvFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: TEXT_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + TEXT_EXTENSION);
  }

  public exportAsHtml(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    const printContent = XLSX.utils.sheet_to_html(worksheet);
    // .replace('<table>', '<table table border="1" cellpadding="0" cellspacing="0" style="border-collapse:collapse;">');

    // console.log(printContent);

    const windowUrl = 'about:blank';
    const windowName = 'Print' + new Date().getTime();
    const printWindow = window.open(windowUrl, windowName, 'left=50000,top=50000,width=0,height=0');

    printWindow.document.write(printContent);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  }

  public exportAsText(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    const txtContent = XLSX.utils.sheet_to_csv(worksheet, { FS: '\t'});
    this.clipboardService.copyFromContent(txtContent);
  }


  // toUTF8Array(str) {
  //   const utf8 = [];
  //   for (let i = 0; i < str.length; i++) {
  //       let charcode = str.charCodeAt(i);
  //       if (charcode < 0x80) {
  //         utf8.push(charcode);
  //       } else if (charcode < 0x800) {
  //         // tslint:disable-next-line:no-bitwise
  //         utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
  //       } else if (charcode < 0xd800 || charcode >= 0xe000) {
  //         // tslint:disable-next-line:no-bitwise
  //         utf8.push(0xe0 | (charcode >> 12), 0x80 | ((charcode>>6) & 0x3f), 0x80 | (charcode & 0x3f));
  //       } else {
  //         // surrogate pair
  //           i++;
  //           // UTF-16 encodes 0x10000-0x10FFFF by
  //           // subtracting 0x10000 and splitting the
  //           // 20 bits of 0x0-0xFFFFF into two halves
  //           // tslint:disable-next-line:no-bitwise
  //           charcode = 0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
  //           // tslint:disable-next-line:no-bitwise
  //           utf8.push(0xf0 | (charcode >> 18), 0x80 | ((charcode >> 12) & 0x3f), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
  //       }
  //   }
  //   return utf8.toString();
  // }
}
