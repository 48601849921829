import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from 'src/app/providers/authentication.service';

import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';


@Component({
    selector: 'app-user-login',
    templateUrl: './user-login.component.html',
    styleUrls: ['./user-login.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgClass, RouterLink]
})
export class UserLoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  site = 0  ;
  siteicon = [ './assets/img/ifaxpro.png', './assets/img/tims-logo.png', './assets/img/gedis-logo.png' ];

  accountInfo = null;
  success = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService)
  {
    console.log('site=', window.location.origin);
    if (window.location.origin.indexOf('fax.tims.fr') !== -1) {
      this.site = 1;
    } else if (window.location.origin.indexOf('fax.gedistelecom.com') !== -1) {
      this.site = 2;
    }
  }

  ngOnInit() {

    console.log('init user-login');

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.loginUser(this.f.username.value, this.f.password.value)
      .subscribe(
        data => {
          this.error = '';
          console.log('user logged in', data);
          this.router.navigate(['/']);
        },
        error => {
          console.log('user failure', error);
          this.error = error.message;
          this.loading = false;
        });
  }

  passwordRequest() {
    let username = this.f.username.value;
    if (username === '') { username = '-'; }
    this.router.navigate([`/password_request/0/${username}`]);
  }

}
