<div class="topbar">
  <nav class="navbar navbar-mycustom w-100">

    <div class="nav-item text-center" style="margin-right: 40px" [routerLink]="['']">
      <div class="navbar-brand">
        <img [src]="siteicon[site]" />
      </div>
    </div>
    @if (isAdmin) {
      <a class="nav-item d-none d-sm-block" style="margin-top: 10px" [routerLink]="['/client/selection']" title="Sélection client">
        <button class="button-menu-mobile open-left">
          <i class="zmdi zmdi-accounts-outline" style="font-size: 36px"></i>
        </button>
      </a>
    }
    <a class="nav-item d-none d-sm-block" style="margin-top: 10px" [routerLink]="['/fax/recus']" title="FAX reçus">
      <button class="button-menu-mobile open-left">
        <i class="fa fa-sign-in" style="font-size: 36px"></i>
      </button>
    </a>
    <div class="nav-item d-none d-sm-block" style="margin-top: 9px" [routerLink]="['/fax/envoyes']" title="FAX envoyés">
      <button class="button-menu-mobile open-left">
        <i class="fa fa-sign-out" style="font-size: 36px"></i>
      </button>
    </div>
    <div class="nav-item d-none d-sm-block" style="margin-top: 9px"  [routerLink]="['/client/lignesfax']" title="Lignes FAX">
      <button class="button-menu-mobile open-left">
        <i class="zmdi zmdi-accounts-list" style="font-size: 36px"></i>
      </button>
    </div>
    <div class="nav-item d-none d-sm-block" style="margin-top: 9px"  [routerLink]="['/contact/list']" title="Contacts">
      <button class="button-menu-mobile open-left">
        <i class="zmdi zmdi-accounts-add" style="font-size: 36px"></i>
      </button>
    </div>
    <div class="nav-item d-none d-sm-block" style="margin-top: 9px" [routerLink]="['/client/edition']" title="Edition client">
      <button class="button-menu-mobile open-left">
        <i class="zmdi zmdi-odnoklassniki" style="font-size: 36px"></i>
      </button>
    </div>
    @if (!isAdmin) {
      <div class="nav-item d-none d-sm-block" style="margin-top: 9px"  [routerLink]="['/nous_contacter']" title="Nous contacter">
        <button class="button-menu-mobile open-left">
          <i class="zmdi zmdi-email" style="font-size: 36px"></i>
        </button>
      </div>
    }
    <div class="nav-item d-none d-sm-block" style="margin-top: 9px; margin-left: auto">
      <button class="button-menu-mobile open-left" (click)="logout()" title="Se déconnecter">
        <i class="zmdi zmdi-power" style="font-size: 36px"></i>
      </button>
    </div>
    @if (watchdog?.actif) {
      <div class="nav-item" style="margin-top: 9px; margin-left: 100px; color:orange">
        Déconnexion dans {{watchdog.countdown}} sec !
      </div>
    }

    <div class="clearfix"></div>
    @if (client?.disabled==1 && !isAdmin) {
      <div class="text-center">
        <div class="text text-warning blink suspendu">Votre compte est suspendu !!</div>
      </div>
    }
  </nav>
</div>


<app-modal [(isActive)]="showLogoutConfirm" [overlayDismiss]="true" (overlayClick)="showLogoutConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Déconnexion ? </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showLogoutConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
      (click)="showLogoutConfirm=false">Annuler</button>
      <button type="button" class="btn btn-primary" (click)='logoutConfirm()'>Valider</button>
    </div>
  </div>
</app-modal>
