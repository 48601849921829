import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventManagerService {

  // tslint:disable-next-line:variable-name
  _events: any;

  currentVal = 1;
  eventUserSelected = this.currentVal++;
  eventMenu2 = this.currentVal++;

  constructor() {
    this._events = {};
  }

  dispatchEvent(event: any, value?: any): void {
    // console.log("EventManager is  Dispatching ", "event : ", event, "value : ",value);
    this.trigger(event, value);
  }

  bind(event, fct) {
    this._events = this._events || {};
    this._events[event] = this._events[event] || [];
    this._events[event].push(fct);
    // console.log('bind', event, this._events[event].length);
  }

  unbind(event, fct) {
    // this._events = this._events || {};
    if (event in this._events === false) {
      return;
    }
    // console.log('unbind', event);
    this._events[event].splice(this._events[event].indexOf(fct), 1);
    // console.log('unbind', event, this._events[event].length);
  }

  unbindEvent(event) {
    // console.log('unbind', event);
    this._events = this._events || {};
    this._events[event] = [];
  }

  listEvent() {
    console.log('list all event, fct', this._events) ;
  }

  unbindAll() {
    this._events = this._events || {};
    for (const event in this._events) {
      if (this._events) {
        this._events[event] = false;
      }
    }
  }

  // event, ...args: any[])
  trigger(event, value: any) {
    this._events = this._events || {};
    if (event in this._events === false) { return; }
    for (const evt of this._events[event]) {
      evt.apply(this, Array.prototype.slice.call(arguments, 1));
    }
  }

  registerEvent(evtname) {
    this[evtname] = function(callback, replace) {
      if (typeof callback === 'function') {
        if (replace) { this.unbindEvent(evtname); }
        this.bind(evtname, callback);
      }
      return this;
    };
  }
}

