import {
  ViewChildren,
  QueryList,
  Component,
  OnInit,
  AfterViewInit,
  Input,
  OnDestroy
} from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import {
  SortEvent,
  NgbdSortableHeader
} from 'src/app/directives/sortable.directive';
import { environment } from '../../../environments/environment';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router } from '@angular/router';

import * as R from 'ramda';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IUser } from 'src/app/interfaces/user';
import { IFaxLine, FaxLine } from 'src/app/interfaces/faxline';
import {AuthenticationService} from 'src/app/providers/authentication.service';
import { StrReplacePipe } from '../../pipes/str-replace.pipe';
import { ModalComponent } from '../../modals/modal.component';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-faxline',
    templateUrl: './faxlines.component.html',
    styleUrls: ['./faxlines.component.scss'],
    standalone: true,
    imports: [NgxSpinnerModule, NgIf, FormsModule, NgFor, NgClass, ModalComponent, StrReplacePipe]
})
export class FaxLinesComponent implements OnInit, OnDestroy {

  selectedFaxLine: IFaxLine = new FaxLine();
  @Input() client: IUser;

  isAdmin = false;

  showDeleteConfirm = false;
  showEditFaxline = false;

  faxlines = [];
  clientSubscribe = null;

  constructor(
    public pagingService: PagingService<IFaxLine>,
    private userService: UserService,
    private authService: AuthenticationService,
    private exportDataService: ExportDataService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.clientSubscribe = this.userService.selectedUser$.subscribe(user => {
      if (user) {
        this.client = R.clone(user);
        this.isAdmin = this.authService.isAdmin;
        console.log('faxline onInit', this.client);
        if (this.client) {
          this.userService.GetFaxlines(this.client.id).subscribe((lines: FaxLine[]) => {
            this.faxlines = lines;
          });
        }
      }
    });
    setTimeout(() => {
      if (!this.client) {
        this.toastr.warning('Sélectionner un client en premier', '');
        this.router.navigate(['/']);
      }
    }, 1000);

  }

  ngOnDestroy() {
    if (this.clientSubscribe) {
      this.clientSubscribe.unsubscribe();
    }
  }

  selectFaxLine(faxline) {
    this.selectedFaxLine = R.clone(faxline);
  }

  faxlineEdit(faxline) {
    this.selectedFaxLine = R.clone(faxline);
    this.showEditFaxline = true;
  }

  addFaxLine() {
    if (!this.client || this.client.id === 0) {
      return;
    }
    this.selectedFaxLine = new FaxLine();
    this.showEditFaxline = true;
  }

  UpdateFaxline() {
    this.spinner.show();

    if (this.selectedFaxLine.id > 0) {
      this.userService
        .UpdateFaxline(this.selectedFaxLine)
        .subscribe((response: any) => {
          if (response.success) {
            this.toastr.success(
              'La ligne \'' + this.selectedFaxLine.faxnumber + '\' a été modifiée'
            );
            this.userService
              .GetFaxlines(this.client.id)
              .subscribe((lines: []) => (this.faxlines = lines));
          } else {
            this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
          }
          this.spinner.hide();
        });
    } else {
      this.userService
        .AddFaxline(this.client.id, this.selectedFaxLine)
        .subscribe((response: any) => {
          if (response.success) {
            this.toastr.success(
              'La ligne \'' + this.selectedFaxLine.faxnumber + '\' a été ajoutée'
            );
            this.userService
              .GetFaxlines(this.client.id)
              .subscribe((lines: []) => (this.faxlines = lines));
          } else {
            this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
          }
          this.spinner.hide();
        });
    }
    this.showEditFaxline = false;
  }

  faxlineDelete(faxline) {
    this.selectedFaxLine = faxline;
    this.showDeleteConfirm = true;
    console.log('show delete confirm');
  }

  DeleteConfirm() {
    this.showDeleteConfirm = false;
    console.log('call delete api');
    this.spinner.show();

    this.userService
      .DeleteFaxline(this.client.id, this.selectedFaxLine.id)
      .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('La ligne a été supprimée', '');
          this.userService
            .GetFaxlines(this.client.id)
            .subscribe((lines: []) => (this.faxlines = lines));
        } else {
          this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
        }
        this.spinner.hide();
      });
  }

  showUser(line) {
    if (!line.showUser) {
      line.showUser = true;
    } else {
      line.showUser = !line.showUser;
    }
  }

  userUnauthorize(user) {
    user.banned = (user.banned === '1' ? '0' : '1');
    this.userService.UpdateUser(user).subscribe((response: any) => {
      if (response.success) {
          if (user.banned === 0) {
            this.toastr.success('L\'utilisateur \'' + user.email + '\' est autorisé');
          } else {
            this.toastr.success('L\'utilisateur \'' + user.email + '\' est bloqué');
          }
      } else {
          // restore value
          user.banned = (user.banned === '1' ? '0' : '1');
      }
    });
  }

}
