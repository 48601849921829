import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';

export interface IContactList {
  id: number;
  customerid: number;
  name:	string;
  count: number;
  actif: number;
  mobile: string;
  fields: string;
  datecreation: string;
  longurl: string;
}
export interface IContact {
  id: number;
  clistid: number;
  json_data: string;
  selected: number;
}

export class ContactList {
  id: number;
  customerid: number;
  name:	string;
  count: number;
  actif: number;
  mobile: string;
  fields: string;
  datecreation: string;
  longurl: string;
  constructor(customerid) {
    this.id = 0;
    this.customerid = customerid;
    this.name = '';
    this.count = 0;
    this.actif = 0;
    this.mobile = '';
    this.fields = '';
    this.datecreation = '';
    this.longurl = '';
  }
}
export class Contact {
  id: number;
  clistid: number;
  // tslint:disable-next-line:variable-name
  json_data: string;
  selected: number;
  constructor(clistid) {
    this.clistid = clistid;
    this.id = 0;
    this.json_data = '';
    this.selected = 0;
  }
}

