import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  currentUrl = null;
  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    console.log(this.currentUrl);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('currentUser') ||
        sessionStorage.getItem('currentAdmin') ) {
      // logged in so return true
      return true;
    }
    console.log('canActivate');
    // not logged in so redirect to login page with the return url
    if (this.currentUrl === '/' || this.currentUrl === '/login') {
      this.router.navigate(['/login']);
    }  else {
      this.router.navigate(['/admin']);
    }
    return false;
  }
}
