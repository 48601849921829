import { Component, OnInit, OnDestroy } from '@angular/core';
import { IUser } from 'src/app/interfaces/user';
import { UserService } from 'src/app/providers/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { MailService } from 'src/app/providers/mail.service';
import { Router, RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-nous-contacter',
    templateUrl: './nous-contacter.component.html',
    styleUrls: ['./nous-contacter.component.scss'],
    standalone: true,
    imports: [NgxSpinnerModule, FormsModule, RouterLink]
})
export class NousContacterComponent implements OnInit, OnDestroy {

  client: IUser;
  userSubscribe = null;

  contactmail = {
    id: 0,
    admin: 0,
    email_admin: '',
    name: '',
    company: '',
    username: '',
    subject: '',
    message: ''
  };

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private mailService: MailService,
    private router: Router,
  ) {

    this.userSubscribe = this.userService.selectedUser$.subscribe(user => {
      if (user) {
        this.client = user;
        this.contactmail = {
          id: this.client.id,
          admin: this.client.admin,
          email_admin: this.client.email_admin,
          name: this.client.name + ' ' + this.client.firstname,
          company: this.client.company,
          username: this.client.username,
          subject: '',
          message: ''
        };
      }
    });

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
  }

  SendMail() {
    this.spinner.show();
    this.mailService.Send(this.contactmail).subscribe( (response: any) => {

      if (response.success) {
        this.toastr.success('Votre message a été envoyé', '');
        this.router.navigate(['/']);
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.spinner.hide();

    });

  }
}


