<div class="client-view p-md-4">
  <ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
    [fullScreen]="false">
  </ngx-spinner>

  <app-client-select [selectedUser]="client"></app-client-select>

  <div class="card">
    <div class="card-body">

      <form ng-app="fileUpload" name="formupload">

        <div class="row">
          <div class="form-group col-md-6 col-xs-12">
            <h5>Fichier à envoyer:</h5>
            <h3>{{pdfFile.name}}</h3>
            <br>Taille: {{ pdfFile.size }} Kb / Nombre de pages: {{pdfFile.pagecount}}
              <!-- <br>{{pdfFile.progress}} -->
            </div>
            <div class="form-group col-md-6 col-xs-12">
              <div class="form-inline">

                <div class="dropzone-custom">
                  <div class="dropzone-label">Glisser votre document ou cliquez ICI</div>
                  <input type="file"
                    accept="application/pdf" (change)="onFilesAdded($event.target.files)"
                    multiple="false"
                    mode="replace"
                    />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div style="clear:both;"></div>
        <hr />
        <form name="form" role="form" autocomplete="off" autocapitalize="off" autocorrect="off" form-autofill-fix>

          <label class="h5 col-sm-6 control-label" for="selectfaxline">Envoyer depuis la ligne:</label>
          <div class="form-group">

            <div class="row">
              <div class="offset-6 col-md-6 col-xs-12">
                <select [(ngModel)]="pdfFile.faxline" class="form-control" name="faxline">
                  @for (line of faxlines; track line) {
                    <option [ngValue]="line">{{line.ligneportee + ' / ' + line.name}}
                    </option>
                  }
                </select>
              </div>
            </div>
          </div>
          <div style="clear:both;"></div>
          <hr />

          <!--
          <div class="form-check col-md-6 col-xs-12">
            <h5>Qualité d'envoi</h5>
            <div class="col-12">
              <label class="form-check-label" for="quality1">
                <input type="radio" id="quality1" name="quality" [(ngModel)]="pdfFile.quality" value='high'/>
                Haute
              </label>
              <label class="form-check-label" for="quality2">
                <input type="radio" id="quality2" name="quality" [(ngModel)]="pdfFile.quality" value='normal'/>
                Standard
              </label>
            </div>
          </div>
          <div style="clear:both;"></div>
          <hr/>
          -->
          <div class="form-check">
            <h5>Date d'envoi</h5>
            <div class="row">
              <div class="col-md-6 col-xs-12">
                <label class="form-check-label" for="envoi1">
                <input type="radio" id="envoi1" name="envoi" [(ngModel)]="pdfFile.envoi" value='1' /> Immédiat </label>
                &nbsp;&nbsp;&nbsp;
                <label class="form-check-label" for="envoi2">
                <input type="radio" id="envoi2" name="envoi" [(ngModel)]="pdfFile.envoi" value='2' /> Différé </label>
              </div>
              <div class="col-md-6 col-xs-12">
                <div class="row">
                  <label for="startdate">Date de début de période</label>
                  <div class="input-group col-6">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="startdate" [(ngModel)]="pdfFile.date"
                      ngbDatepicker #d1="ngbDatepicker" (dateSelect)="checkDate()" (click)="d1.toggle()" [disabled]="pdfFile.envoi==1">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary btn-grey" (click)="d1.toggle()" type="button">
                        <i class="fa fa-calendar"></i>
                      </button>
                    </div>
                  </div>

                  <label for="hour">à</label>
                  <div class="input-group col-6">
                    <input type="text" class="form-control" id="hour" name="hour" [(ngModel)]="pdfFile.time"
                      autocomplete="off" autocapitalize="off" autocorrect="off" [disabled]="pdfFile.envoi==1">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="clear:both;"></div>
          <hr />
          <div class="row">
            <div class="col-md-6 col-xs-12">
              @if (client && client.option_entete_admin==0) {
                <div class="form-check">
                  <label class="form-check-label h5 ml-3">
                    <input type="checkbox"
                      class="form-check-input"
                      [checked]="pdfFile.option_entete == 1"
                      (change)="pdfFile.option_entete = $event.target.checked ? 1 : 0" name="option_entete" />
                    &nbsp;&nbsp;&nbsp;Ajouter une page d'entete
                  </label>
                </div>
              }
            </div>
          </div>
          @if (pdfFile.option_entete=='1') {
            <div class="row">
              <div class="col-md-6 col-xs-12"><b>De: </b>{{ pdfFile.faxline.ligneportee}} / {{ pdfFile.faxline.name }}</div>
              <div class="col-md-6 col-xs-12"><b>Email: </b>{{client.username}}</div>
            </div>
          }

          <div class="col-12">
            <div class="form-group">
              <label for="faxnumbers">Message d'en-tête</label>
              <textarea name="message_entete" id="message_entete" class="form-control"
                [(ngModel)]="pdfFile.message_entete" style="resize:vertical;margin-bottom:10px;width:100%"
                placeholder="Message d'en-tête facultatif."
              [disabled]="pdfFile.option_entete=='0'"></textarea>
            </div>
          </div>
          <div style="clear:both;"></div>
          <hr />
          <div class="col-12">
            <div class="form-group">
              <label for="faxnumbers">Numéro de FAX destinataire:</label>
              <input type="text" name="faxnumbers" id="faxnumbers" class="form-control" [(ngModel)]="pdfFile.faxnumbers"
                autocomplete="off" autocapitalize="off" autocorrect="off" required />
            </div>
          </div>
          <div style="clear:both;"></div>


          <div class="form-actions" style="margin: 10px">

            <button [disabled]="dataLoading || pdfFile?.faxnumbers?.length == 0" class="btn btn-success btn-lg"
              (click)="sendDocument()">
              <i class="fa fa-sign-out"></i>&nbsp;<span>Envoyer le document</span>
            </button>

            <div class="btn btn-secondary btn-lg pull-right" (click)="AddCampaignCancel()">Annuler</div>
          </div>
          <br>
            <br>


              <div class="col-12" style="border: solid 1px #1bb99a; border-radius: 10px; background: #fff;">

                <ul class="nav nav-pills mb-n3" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active': activeTab===0}" id="tab0" data-toggle="tab"
                    (click)="selectTab(0)" role="tab" aria-controls="annuaires" aria-selected="true">Annuaires</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active': activeTab===1}" id="tab1" data-toggle="tab"
                    (click)="selectTab(1)" role="tab" aria-controls="contacts" aria-selected="false">Liste de contacts</a>
                  </li>
                </ul>
                <hr>

                  <div class="tab-content" id="myTabContent">
                    @if (activeTab === 0) {
                      <div class="tab-pane fade show active p-4" id="tab0" role="tabpanel" aria-labelledby="annuaires"
                        >
                        <h5>Votre annuaire</h5>
                        <div class="col-12 offset-sm-1 col-sm-10">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th>Entreprise</th>
                                <th>Nom</th>
                                <th>N° Fax</th>
                              </tr>
                            </thead>
                            <tbody>
                              @for (a of annuaires; track a) {
                                <tr>
                                  <td>{{a.company}}</td>
                                  <td>{{a.lastname}} {{a.firstname}}</td>
                                  <td>{{a.faxnumber}}</td>
                                  <td>
                                    <button type="button" title="Ajouter" (click)="addDest(a)"
                                      class="btn btn-primary contact">
                                      <i class="fa fa-plus"></i>
                                    </button>
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    }
                  </div>

                  <div class="tab-content" id="myTabContent">
                    @if (activeTab === 1) {
                      <div class="tab-pane fade show active p-4" id="tab1" role="tabpanel" aria-labelledby="contact"
                        >
                        <h5>Liste de contacts</h5>
                        <div class="col-12">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th>Id</th>
                                <th>Nom</th>
                                <th>#contacts</th>
                              </tr>
                            </thead>
                            <tbody>
                              @for (c of contactlists; track c) {
                                <tr>
                                  <td>{{c.id}}</td>
                                  <td>{{c.name}}</td>
                                  <td>{{c.count}}</td>
                                  <td>
                                    <button type="button" title="Sélectionner"
                                      (click)="contactListSelect(c)" class="btn btn-primary contact">
                                      <i class="fa fa-plus"></i>
                                    </button>
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
