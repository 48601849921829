import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {


  constructor(
    private httpClient: HttpClient,
  ) {}

  GetAll(clientid): Observable<any> {
    const url = environment.apiUrl + '/documents/get/' + clientid;
    return this.httpClient.get(url);
  }

  GetContent(clientid, docid): Observable<any> {
    const url = environment.apiUrl + '/documents/content/' + clientid + '/' + docid;
    return this.httpClient.get(url);
  }

  Mail(clientid, docid): Observable<any> {
    const url = environment.apiUrl + '/documents/mail/' + clientid + '/' + docid;
    return this.httpClient.get(url);
  }

  Add(clientid, document): Observable<any> {
    const url = environment.apiUrl + '/documents/' + clientid;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post(url, document, {headers});
  }

  Delete(clientid, documentid): Observable<any> {
    const url = environment.apiUrl + '/documents/' + clientid + '/' + documentid;
    return this.httpClient.delete(url);
  }

}
