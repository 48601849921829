<ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
  [fullScreen]="false">
</ngx-spinner>

<div class="card">
  <div class="card-body">

    <form class="form-inline" *ngIf="isAdmin">
      <div class="form-group">
        <button class="btn btn-success" (click)="addFaxLine()">
          <i class="fa fa-plus"></i>&nbsp;Ajouter une ligne</button>
      </div>
    </form>
    <br>


    <div class="clearfix">

      <div class="p-20">

        <div class="col-xs-12">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>N° de ligne</th>
                <th>Description</th>
                <th *ngIf="isAdmin">Ligne portée</th>
                <th>Envoyés / Reçus / Pages</th>
                <th>Emails notifiés</th>
                <th>Notif slmt erreurs</th>
                <th>Emails autorisés</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let l of faxlines">
                <tr>
                  <td>{{l.faxnumber}}</td>
                  <td>{{l.name}}</td>
                  <td *ngIf="isAdmin">{{l.ligneportee}}</td>
                  <td>{{l.faxsend}} / {{l.faxrcvd}} / {{l.faxpages}}</td>
                  <td>{{l.notifemail | replace:';':' ' }}</td>
                  <td>{{l.notifonlyerror === '1' ? 'X' : ''}}</td>
                  <td>{{l.authemail | replace:';':' ' }}</td>
                  <td class="actions">
                    <button type="button" title="Editer" (click)="faxlineEdit(l)" class="btn btn-primary m-l-5 w40">
                      <i class="fa fa-edit"></i>
                    </button>
                    <button type="button" title="Supprimer" (click)="faxlineDelete(l)" class="btn btn-danger m-l-5 w40">
                      <i class="fa fa-trash-o"></i>
                    </button>
                    <button type="button" title="Supervision" (click)="showUser(l)" class="btn btn-default m-l-5 w40">
                      <i class="fa fa-user"></i>
                    </button>
                  </td>
                <tr>
                <tr *ngIf="l.users.length>0 && l.showUser">
                  <td>&nbsp;</td>
                  <td colspan='5'>
                    <table>
                      <thead>
                        <tr>
                          <th>Email</th>
                          <th>Envoyés / Pages</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let u of l.users">
                          <td>{{u.email}}</td>
                          <td>{{u.faxsend}} / {{u.faxpages}}</td>
                          <td style="display:flex">
                            <div *ngIf="u.banned==='1'" class="banned">bloqué</div>
                            <div *ngIf="u.banned==='0'" class="banned">autorisé</div>
                            <button type="button" style="margin-top: -8px" title="Bloquer"
                              (click)="userUnauthorize(u)" class="btn btn-success m-l-5 w40"
                              [ngClass]="{'btn-danger': u.banned==='1'}">
                              <i class="fa fa-hand-stop-o"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr *ngIf="l.users.length==0 && l.showUser">
                  <td>&nbsp;</td>
                  <td colspan='3'>Pas d'utilisateurs enregistrés</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <br />
        <br />
      </div>
    </div>
  </div>
</div>


<app-modal [(isActive)]="showDeleteConfirm" [overlayDismiss]="true" (overlayClick)="showDeleteConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Suppression d'une ligne</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showDeleteConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Confirmez-vous la suppression de cette ligne ?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)='DeleteConfirm()'>Confirmer</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="showDeleteConfirm=false">Annuler</button>
    </div>
  </div>
</app-modal>


<app-modal [(isActive)]="showEditFaxline" [overlayDismiss]="false" [isLarge]="true">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{selectedFaxLine.id == 0 ? 'Ajout' : 'Edition'}} ligne</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showEditFaxline=false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form role="form" name="form">

          <div class="form-group">
            <label for="faxnumber">N° de ligne</label>
            <input type="text" name="faxnumber" id="faxnumber" class="form-control"
              [(ngModel)]="selectedFaxLine.faxnumber" autocomplete="off" autocapitalize="off" autocorrect="off" required
              [readOnly]='selectedFaxLine.id>0' #labelNumber="ngModel"
              [ngClass]="{ 'is-invalid': labelNumber.invalid }" />
            <span *ngIf="labelNumber.invalid" class="help-block">Saisissez un n° de téléphone</span>
          </div>
          <div *ngIf="isAdmin" class="form-group">
            <label for="account">Compte</label>
            <input type="text" name="account" id="account" class="form-control" [(ngModel)]="selectedFaxLine.account"
              autocomplete="off" autocapitalize="off" autocorrect="off" required [readOnly]='selectedFaxLine.id>0'
              #labelAccount="ngModel" [ngClass]="{ 'is-invalid': labelAccount.invalid }" />
            <span *ngIf="labelAccount.invalid" class="help-block">Saisissez le numéro de compte</span>
          </div>
          <div class="form-group">
            <label for="name">Description (laisser vide pour récupération depuis configuration)</label>
            <input type="text" name="name" id="name" class="form-control" [(ngModel)]="selectedFaxLine.name" [readOnly]="!isAdmin"
              autocomplete="off" autocapitalize="off" autocorrect="off" />
          </div>
          <div *ngIf="isAdmin" class="form-group">
            <label for="account">N° de ligne portée (facultatif)</label>
            <input type="text" name="ligneportee" id="ligneportee" class="form-control"
              [(ngModel)]="selectedFaxLine.ligneportee" autocomplete="off" autocapitalize="off" autocorrect="off" />
          </div>
          <div class="form-group">
            <label for="notifemail">Emails à notifier lors de la réception</label>
            <textarea [(ngModel)]="selectedFaxLine.notifemail" style="resize:vertical;margin-bottom:10px;width:100%"
              name="notifemail" placeholder="Liste des emails à notifier séparés par ';'" rows="3" required
              #labelNotifEmail="ngModel" [ngClass]="{ 'is-invalid': labelNotifEmail.invalid }"></textarea>
            <div *ngIf="labelNotifEmail.invalid" class="help-block" style="margin-top:-15px">Saisissez une adresse Email</div>
          </div>
          <div class="form-group">
            <div class="checkbox checkbox-primary">
              <input type="checkbox" [checked]="selectedFaxLine.notifonlyerror == 1" id="notif" name="notif"
                (change)="selectedFaxLine.notifonlyerror = $event.target.checked ? 1 : 0">
              <label for="notif">&nbsp;&nbsp;Notifier seulement les erreurs</label>
            </div>
          </div>
          <div class="form-group">
            <label for="authemail">Emails ou domaines autorisés</label>
            <textarea [(ngModel)]="selectedFaxLine.authemail" style="resize:vertical;margin-bottom:10px;width:100%"
              name="authemail" placeholder="Liste des emails ou @domaines autorisés séparés par ';'" rows="3" required
              #labelAuthEmail="ngModel" [ngClass]="{ 'is-invalid': labelAuthEmail.invalid }">
            </textarea>
            <div *ngIf="labelAuthEmail.invalid" class="help-block" style="margin-top:-15px">Saisissez adresse Email ou nom de domaine</div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"
          (click)='UpdateFaxline()'>{{ selectedFaxLine.id == 0 ? 'Ajouter' : 'Modifier'}}</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
          (click)="showEditFaxline=false">Annuler</button>
      </div>
    </div>
  </div>
</app-modal>
