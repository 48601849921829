<div class="client-view p-md-4">

  <ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
    [fullScreen]="false">
  </ngx-spinner>

  <div class="card ">
    <div class="card-block p-4">

        <div class="row">

        <div class="col-md-6">
          <p>Vous rencontrez des difficultés ?<br>
             Envoyez nous vos questions et remarques.</p>

        </div>

        <div class="col-md-6 text-right">
          Votre contact:
            <ul class="list-unstyled" style="margin-left:20px; font-size: 18px; font-weight: 500">
              <li><i class="fa fa-envelope-o"></i>&nbsp;&nbsp;{{client?.email_admin}}</li>
              <li><i class="fa fa-phone"></i>&nbsp;&nbsp;{{client?.adminphone}}</li>
            </ul>
        </div>
        </div>

            <form class="form" role="form">
              Vos infos:
              <div class="d-flex flex-row grey">
                  <div class="col-md-4 margin-bottom-sm-20">
                    <input type="text" class="form-control" id="contact-name" name="contact-name" placeholder="Nom" [(ngModel)]="contactmail.name" disabled/>
                  </div>
                  <div class="col-md-4">
                    <input type="text" class="form-control" id="contact-company" name="contact-company" placeholder="Société"  [(ngModel)]="contactmail.company" disabled/>
                  </div>
                  <div class="col-md-4">
                    <input type="email" class="form-control" id="contact-email" name="contact-email" placeholder="Email"  [(ngModel)]="contactmail.username" disabled/>
                  </div>
              </div>

              Votre message:
              <div class="form-group">
                <div class="col-xs-12">
                  <input type="text" class="form-control" id="contact-subject" name="contact-subject" placeholder="Sujet"  [(ngModel)]="contactmail.subject"/>
                </div> <!-- /col-xs-12 -->
              </div> <!-- /form-group -->

              <div class="form-group">
                <div class="col-xs-12">
                  <textarea class="form-control" rows="8" id="contact-message" name="contact-message" placeholder="Message" [(ngModel)]="contactmail.message"></textarea>
                </div> <!-- /col-xs-12 -->
              </div> <!-- /form-group -->

              <div class="form-group">
                <div class="col-xs-12">
                  <div class="btn btn-success" (click)="SendMail()">Envoyer</div>
                  <div class="btn btn-secondary pull-right" [routerLink]="['/']">Annuler</div>
                </div> <!-- /col-xs-12 -->
              </div> <!-- /form-group -->
            </form>

    </div>
  </div>
</div>
