import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { UserService } from 'src/app/providers/user.service';
import { environment } from '../../../environments/environment';
import { IUser } from 'src/app/interfaces/user';
import { Toast, ToastrService } from 'ngx-toastr';
import { ModalComponent } from '../../modals/modal.component';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    standalone: true,
    imports: [ModalComponent]
})
export class DashboardComponent implements OnInit {

  // @Input() tabs;
  // @Output() changeTabEvent: EventEmitter<number> = new EventEmitter<number>();

  menu = {
    none: 0,
    list : 1,
    campCreate: 2,
    campManage: 3
  };
  submenu = this.menu.none;

  client: IUser;
  clientMLP = false;
  clientADE = false;
  showLogoutConfirm = false;

  constructor(
    private router: Router,
    public authService: AuthenticationService,
    private userService: UserService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit() {
    console.log('init dashboard');
    this.userService.getCurrentClient();
    this.userService.selectedUser$.subscribe( user => {
      if (user) {
        this.client = user;
        this.clientMLP = (this.client.company === 'MLP');
        this.clientADE = (this.client.company === 'ADE');
      }
    });
  }

  openLink(link) {
    console.log('openLink', link);
    if (this.client) {
      this.router.navigate([link]);
    } else {
      this.toastr.info('Sélectionnez un client en premier !', '');
    }
  }

  openLinkNoCheck(link) {
    this.router.navigate([link]);
  }

  openMenu(choice) {
    console.log('openMenu', choice);
    if (this.client) {
      if (this.submenu === choice) {
        this.submenu = this.menu.none;
      } else {
        this.submenu = choice;
      }
    } else {
      this.toastr.info('Sélectionnez un client en premier !', '');
    }
  }

  closeMenu() {
    this.submenu = this.menu.none;
  }

  logout() {
    this.showLogoutConfirm = true;
  }

  logoutConfirm() {
    this.showLogoutConfirm = false;
    this.authService.logout();
    this.userService.clearSelectedUser();
  }

  openTutos() {
    let url = environment.apiUrl + '/show_pdf.php?id=0&docid=0';
    if (window.location.origin.indexOf('fax.tims.fr') !== -1) {
      url = 'https://fax.tims.fr/api/show_pdf.php?id=0&docid=2';
    } else if (window.location.origin.indexOf('fax.gedistelecom.com') !== -1) {
      url = 'https://fax.gedistelecom.com/api/show_pdf.php?id=0&docid=3';
    }
    window.open(
      url,
      '',
      'toolbar=no, menubar=no,scrollbars=yes,resizable=yes,location=no,directories=no,status=no, width=1000, height=700');
  }

}
