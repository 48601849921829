import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Event as NavigationEvent } from '@angular/router';
import { NavigationStart } from '@angular/router';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent {

  constructor(
    public router: Router,
  ) {

    // istanbul ignore next
    router.events
      .pipe(filter((event: NavigationEvent) => {
        // console.log('navigation event: ', event);
        return (event instanceof NavigationStart);
      }))
      .subscribe((event: NavigationStart) => {
        // console.log('Navigation route:', event.url);
        if (event.navigationTrigger === 'popstate') {
          if (event.url === '/login'
            || event.url === '/admin') {
            // set isBackButtonClicked to true => AuthGuard.canDeativate  will return false
            // this.toolsService.setBackClicked(true);
            return false;
          }
        }
      });
  }
}
