import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnnuaireService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  GetAll(clientid): Observable<any> {
    const url = environment.apiUrl + '/annuaires/' + clientid;
    return this.httpClient.get(url);
  }

  Reset() {
    return {
      id: 0,
      company: '',
      service: '',
      firstname: '',
      lastname: '',
      faxnumber: '',
      telnumber: '',
      email: '',
    };
  }

  Add(clientid, annuaire): Observable<any> {
    const url = environment.apiUrl + '/annuaires/' + clientid;
    return this.httpClient.post(url, annuaire);
  }

  Update(clientid, annuaire): Observable<any> {
    const url = environment.apiUrl + '/annuaires/' + clientid + '/' + annuaire.id;
    return this.httpClient.put(url, annuaire);
  }

  Delete(clientid, annuaireid): Observable<any> {
    const url = environment.apiUrl + '/annuaires/' + clientid + '/' + annuaireid;
    return this.httpClient.delete(url);
  }
}
