import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HomePageComponent } from '../home-page/home-page.component';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { UserService } from 'src/app/providers/user.service';
import { ModalComponent } from '../../modals/modal.component';

import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-menu-header',
    templateUrl: './menu-header.component.html',
    styleUrls: ['./menu-header.component.scss'],
    standalone: true,
    imports: [RouterLink, ModalComponent]
})
export class MenuHeaderComponent implements OnInit {

  @Output() changeTabEvent: EventEmitter<number> = new EventEmitter<number>();

  isAdmin = this.authService.isAdmin;
  watchdog = { idle: 3000,  // 30 min
    timeout: 60, // 60 sec
    countdown: 60,
    actif: false };

  client = {
    disabled: '0',
  };

  site = 0;
  siteicon = [ './assets/img/ifaxpro.png', './assets/img/tims-logo.png', './assets/img/gedis-logo.png' ];

  showLogoutConfirm = false;

  constructor(
    private homePageComponent: HomePageComponent,
    private authService: AuthenticationService,
    private userService: UserService,
  ) {
    if (window.location.origin.indexOf('fax.tims.fr') !== -1) {
      this.site = 1;
    } else if (window.location.origin.indexOf('fax.gedistelecom.com') !== -1) {
      this.site = 2;
    }
  }

  ngOnInit() {
    console.log('header init');
    this.userService.selectedUser$.subscribe( user => {
      if (user) this.client.disabled = user.disabled;
    });
  }

  changeTab(choice) {
    this.changeTabEvent.emit(choice);
  }

  logout() {
    this.showLogoutConfirm = true;
  }

  logoutConfirm() {
    this.showLogoutConfirm = false;
    this.authService.logout();
    this.userService.clearSelectedUser();
  }


}
