import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { UserService } from 'src/app/providers/user.service';
import { RouterLink } from '@angular/router';


@Component({
    selector: 'app-client-select',
    templateUrl: './client-select.component.html',
    styleUrls: ['./client-select.component.scss'],
    standalone: true,
    imports: [RouterLink]
})
export class ClientSelectComponent implements OnInit {

  @Input() selectedUser = null;

  constructor(
    public authService: AuthenticationService,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

}
