
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(
    private httpClient: HttpClient,
  ) {}

  Send(message): Observable<any> {
    const url = environment.apiUrl + '/sendmail';
    return this.httpClient.post(url, message);
  }

  accountCreated(userid, password): Observable<any> {
    const url = environment.apiUrl + '/sendmail/' + userid + '/accountcreation/' + password;
    return this.httpClient.get(url);
  }

}
