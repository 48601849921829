<div class="main-container">

  <div class="header">
    <a class="navbar-brand" href="#/">
      <img [src]="siteicon[site]" height="50" />
    </a>
  </div>

  <div class="" style="margin-top:150px;">
    <div id="loginbox" class="mainbox col-sm-8 offset-sm-2 col-xs-12">

      <div class="card card-block p-2">
        @if (etape==1) {
          <div class="col-12">
            <div class="">
              <p>Un Email contenant un code de confirmation va vous être envoyé à l'adresse suivante:</p>
            </div>
            <div class="col">
              <form role="form" name="form">
                <div class="form-group">
                  <div style="margin-bottom: 25px" class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fa fa-user"></i></span>
                    </div>
                    <input type="email" name="username" class="form-control" [(ngModel)]="username" required email
                      #usernameRef="ngModel" [ngClass]="{ 'is-invalid': usernameRef.invalid }" />
                  </div>
                  @if (usernameRef.dirty && usernameRef.errors?.required) {
                    <span class="text-danger">Champ
                    obligatoire</span>
                  }
                  @if (usernameRef.dirty && usernameRef.errors?.email) {
                    <span class="text-danger">Email invalide</span>
                  }
                </div>
                <div class="form-actions">
                  <button class="btn btn-success" (click)="pwdRequestCode()">Demander un code</button>
                  <button class="btn btn-default" (click)="pwdBackLogin()">Annuler</button>
                </div>
              </form>
            </div>
          </div>
        }

        @if (etape==2) {
          <div class="col-12">
            <div class="">
              <p>Un code de confirmation vous a été envoyé par Email.<br>Merci de renseigner ce code</p>
            </div>
            <form role="form" name="form">
              <div class="form-group">
                <div style="margin-bottom: 25px" class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-code"></i></span>
                  </div>
                  <input type="text" name="code" class="form-control" [(ngModel)]="code" required #codeRef="ngModel" />
                </div>
                @if (codeRef.dirty && codeRef.errors?.required) {
                  <span class="text-danger">Champ
                  obligatoire</span>
                }
              </div>
              <div class="form-actions">
                <button class="btn btn-success" (click)="pwdCheckCode()">Valider le code</button>
                <button class="btn btn-default" (click)="pwdBackLogin()">Annuler</button>
              </div>
            </form>
          </div>
        }

        @if (etape==3) {
          <div>
            <div class="">
              <p>Saisissez votre nouveau mot de passe:</p>
            </div>
            <form role="form" name="form">
              <div class="form-group">
                <div style="margin-bottom: 25px" class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-unlock"></i></span>
                  </div>
                  <input type="password" name="password1" class="form-control" [(ngModel)]="newpassword1" required
                    #password1Ref="ngModel" />
                </div>
                @if (password1Ref.dirty && password1Ref.errors?.required) {
                  <span class="text-danger">Champ
                  obligatoire</span>
                }
                <div style="margin-bottom: 25px" class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-lock"></i></span>
                  </div>
                  <input type="password" name="password2" class="form-control" [(ngModel)]="newpassword2" required
                    #password2Ref="ngModel" />
                </div>
                @if (password2Ref.dirty && newpassword1 !== newpassword2) {
                  <span class="text-danger">Les mots de passe ne correspondent pas</span>
                }
              </div>
              <div class="form-actions">
                <button class="btn btn-success" (click)="pwdChange()">Modifer</button>
                <button class="btn btn-default" (click)="pwdBackLogin()">Annuler</button>
              </div>
            </form>
          </div>
        }
      </div>


    </div>
  </div>
</div>
