
<div class="client-view p-md-4">

  <app-client-select [selectedUser]="client"></app-client-select>

  <ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
    [fullScreen]="false">
  </ngx-spinner>

  <ul class="nav nav-pills mb-n3" id="myTab" role="tablist">
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': activeTab==='coord'}" id="coord-tab" data-toggle="tab"
        (click)="selectTab('coord')" role="tab" aria-controls="coord" aria-selected="true">Identifiants /
      Coordonnées</a>
    </li>
    @if (client?.id>0) {
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': activeTab==='faxline'}" id="profile-tab" data-toggle="tab"
        (click)="selectTab('faxline')" role="tab" aria-controls="faxline" aria-selected="false">Lignes Fax</a>
      </li>
    }
    @if (client?.id>0) {
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': activeTab==='factures'}" id="contact-tab" data-toggle="tab"
        (click)="selectTab('factures')" role="tab" aria-controls="factures" aria-selected="false">Factures</a>
      </li>
    }
  </ul>
  <hr>

    <div class="tab-content" id="myTabContent">

      @if (activeTab === 'coord') {
        <div class="tab-pane fade show active" id="coord" role="tabpanel" aria-labelledby="coord-tab"
          >
          <div class="card">
            <div class="card-body">
              @if (!client) {
                <div class="alert alert-danger" role="alert">
                  Sélectionner un client !
                </div>
              }
              @else {
                <form name="form" #f="ngForm" autocomplete="off" autocapitalize="off" autocorrect="off">
                  <div class="section">
                    <h4 class="txt18 bold">Identifiants :</h4>
                    <!-- {{client|json}} -->
                    <div class="row">
                      <div class="form-group col-sm-6 col-xs-12">
                        <label for="username">Votre Email</label>
                        <input type="text" name="username" [(ngModel)]="client.username" class="form-control"
                          [disabled]="!isAdmin" autocomplete="off" autocapitalize="off" autocorrect="off" required email
                          #usernameRef="ngModel" [ngClass]="{ 'is-invalid': usernameRef.invalid }" />
                        @if ((usernameRef.dirty && usernameRef.errors?.email) || client.email_invalid==1) {
                          <div
                          class="text-danger">Email invalide</div>
                        }
                        @if (usernameRef.dirty && usernameRef.errors?.required) {
                          <div class="text-danger">Champ obligatoire
                          </div>
                        }
                      </div>
                      <div class="form-group col-sm-6 col-xs-12" [ngClass]="{ 'has-error': client.email_invalid==1 }">
                        <label for="username">Clef d'API</label>
                        <input type="text" name="apikey" [(ngModel)]="client.apikey" class="form-control" readonly
                          autocomplete="off" autocapitalize="off" autocorrect="off" />
                        <div class="btn btn-primary" (click)="genkey()">
                          <i class="fa fa-cog"></i>&nbsp;&nbsp;Génerer une clef</div>
                          <div class="btn btn-info" (click)="copyKey()">
                            <i class="fa fa-copy"></i>&nbsp;&nbsp;Copier</div>
                            @if (showSaveKey) {
                              <div class="help-block text-warning blink">N'oubliez pas de sauvegarder vos
                              informations</div>
                            }
                            <br>
                            </div>
                          </div>
                          <br>
                            <div class="form-row">
                              @if (isAdmin && client.id != 0) {
                                <div class="form-group col-sm-6 col-xs-12">
                                  @if (client?.disabled==1) {
                                    <div>
                                      <h5>Le client est suspendu !!</h5>
                                      <div class="btn btn-warning" (click)="Disable()">Activer le client</div>
                                    </div>
                                  }
                                  @if (client?.disabled==0) {
                                    <div>
                                      <h5>Le client est actif</h5>
                                      <div class="btn btn-primary" (click)="Disable()">Suspendre le client</div>
                                    </div>
                                  }
                                </div>
                              }
                              @if (admins.length>0) {
                                <div class="form-group col-sm-6 col-xs-12">
                                  <label>Administrateur du compte</label>
                                  <select name="admin" [(ngModel)]="client.admin" class="form-control">
                                    @for (admin of admins; track admin) {
                                      <option [value]="admin.id" [selected]="admin.id === client.admin">
                                      {{admin.id +' - '+ admin.username}}</option>
                                    }
                                  </select>
                                </div>
                              }
                            </div>
                          </div>
                          @if (isAdmin) {
                            <div class="section">
                              <hr>
                                <div class="form-check">
                                  <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" id="option_postpaid" name="option_postpaid" [checked]="client.option_postpaid == 1" (change)="changePostpaidOption($event)"/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Utiliser le mode post-payé (Autoriser le solde négatif) pour ce compte
                                    @if (client.option_postpaid != clientOrig.option_postpaid) {
                                      <div
                                      class="help-block text-warning blink">N'oubliez pas de sauvegarder</div>
                                    }
                                  </label>
                                </div>
                                <div class="row">
                                  <div class="form-group col-md-4 col-sm-6">
                                    <label>Période de facturation (mois)</label>
                                    <input name="period" id="period" class="form-control" type="text" [(ngModel)]="client.fact_period">
                                  </div>
                                  <div class="form-group col-md-4 col-sm-6">
                                    <label>N° client Dolibarr</label>
                                    <input name="socid" id="socid" class="form-control" type="text" [(ngModel)]="client.dolibarr_socid">
                                  </div>
                                  <div class="form-group col-md-4 col-sm-6">
                                    <label>FTC (par mois)</label>
                                    <input name="ftc" id="ftc" class="form-control" type="text" [(ngModel)]="client.dolibarr_ftc">
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="form-group col-md-4 col-sm-6">
                                    <label>Prix HT par page (Emission) (standard={{client.price_emis_default}})</label>
                                    <input name="price_emis_ht" id="price_emis_ht" class="form-control" type="text"
                                      [(ngModel)]="client.price_emis_ht">
                                  </div>
                                </div>
                              </div>
                            }
                            <!-- <hr>
                            <div class="col-12">
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input type="checkbox" class="form-check-input" [checked]="client.option_forfait == 1"
                                    name="option_forfait" (change)="changeForfaitOption($event)" #optionForfaitRef />
                                  &nbsp;&nbsp;&nbsp;&nbsp;Utiliser le mode forfait pour ce compte
                                  <div *ngIf="client.option_forfait !== clientOrig.option_forfait"
                                  class="help-block text-warning blink">N'oubliez pas de sauvegarder</div>
                                </label>
                                <div>&nbsp;&nbsp;&nbsp;&nbsp; Date de début de la période : {{client.forfait_firstday}}</div>
                              </div>
                              <div class="row">
                                <div class="form-group col-sm-6">
                                  <label>Période de facturation (mois)</label>
                                  <input name="forfait_period" [(ngModel)]="client.forfait_period" class="form-control" type="text">
                                </div>
                                <div class="form-group col-sm-6">
                                  <label>Quantité Forfait</label>
                                  <input name="forfait_quantity" [(ngModel)]="client.forfait_quantity" class="form-control" type="text">
                                </div>
                              </div>
                            </div> -->
                            <hr>
                              @if (!isAdmin) {
                                <div class="section">
                                  <div class="form-group">
                                    <label>Votre contact :</label>
                                    <input type="text" class="form-control" [value]="client.adminusername" disabled />
                                  </div>
                                </div>
                              }
                              <div class="section">
                                <h5 class="">Coordonnées :</h5>
                                <div class="row">
                                  <div class="col-xs-12 col-sm-6">
                                    <div class="form-group">
                                      <label>Nom</label>
                                      <input type="text" name="lastname" class="form-control" [(ngModel)]="client.name" autocomplete="off"
                                        autocapitalize="off" autocorrect="off" #nameRef="ngModel"
                                        [ngClass]="{ 'is-invalid': nameRef.invalid }" >
                                      @if (nameRef.dirty && nameRef.errors?.required) {
                                        <div class="text-danger">Champ obligatoire</div>
                                      }
                                    </div>
                                    <div class="form-group">
                                      <label>Prénom</label>
                                      <input type="text" name="firstName" class="form-control" [(ngModel)]="client.firstname"
                                        #firstName="ngModel" [ngClass]="{ 'is-invalid': firstName.dirty && firstName.invalid }">
                                    </div>
                                    <!-- <div class="form-group">
                                    <label for="firstname">Prénom</label>
                                    <input type="text" name="firstname" [(ngModel)]="client.firstname" class="form-control"
                                      autocomplete="off" autocapitalize="off" autocorrect="off" />
                                  </div> -->
                                  <div class="form-group">
                                    <label for="company">Société</label>
                                    <input type="text" name="company" [(ngModel)]="client.company" class="form-control"
                                      autocomplete="off" autocapitalize="off" autocorrect="off"
                                      required />
                                  </div>
                                  <div class="form-group">
                                    <label for="email_admin">Email admin</label>
                                    <input type="email" name="email_admin" [(ngModel)]="client.email_admin" class="form-control"
                                      autocomplete="off" autocapitalize="off" autocorrect="off" />
                                  </div>
                                </div>
                                <div class="col-xs-12 col-sm-6">
                                  <div class="form-group">
                                    <label for="address">Adresse</label>
                                    <input type="text" name="address" [(ngModel)]="client.address1" class="form-control"
                                      autocomplete="off" autocapitalize="off" autocorrect="off" />
                                  </div>
                                  <div class="form-group">
                                    <label for="address2">Complément</label>
                                    <input type="text" name="address2" [(ngModel)]="client.address2" class="form-control"
                                      autocomplete="off" autocapitalize="off" autocorrect="off" />
                                  </div>
                                  <div class="form-group">
                                    <label for="postalcode">Code postal</label>
                                    <!--                         <input type="search"
                                    class="form-control"
                                    [(ngModel)]="client.postalcode"
                                    [(ngModel)]="client.asyncpostalcode"
                                    typeahead="postalcodes as postalcodes.code_postal+' '+postalcodes.nom for postalcodes in searchPostalcode($viewValue)"
                                    typeahead-min-length="3"
                                    typeahead-on-select="selectPostalcode(asyncpostalcode)"
                                    typeahead-wait-ms="50" >
                                    -->
                                    <input type="text" name="postalcode" [(ngModel)]="client.postalcode" class="form-control"
                                      autocomplete="off" autocapitalize="off" autocorrect="off" />
                                  </div>
                                  <div class="form-group">
                                    <label for="city">Ville</label>
                                    <input type="text" name="city" [(ngModel)]="client.city" class="form-control" autocomplete="off"
                                      autocapitalize="off" autocorrect="off" />
                                  </div>
                                </div>
                                <div style="clear:both;"></div>
                                <div class="col-xs-12 col-sm-6">
                                  <div class="form-group">
                                    <label for="phone1">Tél 1</label>
                                    <input type="text" name="phone1" [(ngModel)]="client.phone1" class="form-control" autocomplete="off"
                                      autocapitalize="off" autocorrect="off" />
                                  </div>
                                </div>
                                <div class="col-xs-12 col-sm-6">
                                  <div class="form-group">
                                    <label for="phone2">Tél 2</label>
                                    <input type="text" name="phone2" [(ngModel)]="client.phone2" class="form-control" autocomplete="off"
                                      autocapitalize="off" autocorrect="off" />
                                  </div>
                                </div>
                                <div style="clear:both;"></div>
                                <div class="col-xs-12 col-sm-6 form-group">
                                  <label for="name">Vos numéros de FAX</label>
                                  <input type="text" name="faxnumbers" [(ngModel)]="client.faxnumbers" class="form-control" disabled/>
                                </div>
                              </div>
                            </div>
                            <hr>
                              @if (isAdmin) {
                                <div class="form-group">
                                  <label class="control-label" for="option_entete_admin">Page d'en-tête: (choix admin)</label>
                                  <div class="col-xs-12">
                                    <label class="radio-inline" for="forcentete1">
                                      <input type="radio" id="forcentete1" name="option_entete_admin" [(ngModel)]="client.option_entete_value" value='0'/>
                                      Au choix du client&nbsp;&nbsp;&nbsp;
                                    </label>
                                    <label class="radio-inline" for="forcentete2">
                                      <input type="radio" id="forcentete2" name="option_entete_admin" [(ngModel)]="client.option_entete_admin" value='1'/>
                                      Toujours ajouté&nbsp;&nbsp;
                                    </label>
                                  </div>
                                </div>
                              }
                              <br>
                                @if (isAdmin) {
                                  <div class="form-check">
                                    <label class="control-label" for="option_entete">Ajouter la page d'entete (choix client)</label>
                                    <label class="form-check-label" style="margin-left: 50px">
                                      <input type="checkbox" class="form-check-input" id="option_entete" name="option_entete" [checked]="client.option_entete == 1"
                                        (change)="client.option_entete = $event.target.checked ? 1 : 0"/>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ajouter (le contenu du mail)
                                      @if (client.option_entete != clientOrig.option_entete) {
                                        <div
                                        class="help-block text-warning blink">N'oubliez pas de sauvegarder</div>
                                      }
                                    </label>
                                  </div>
                                }
                                @if (!isAdmin && client.option_entete_admin==0) {
                                  <div class="form-check">
                                    <label class="form-check-label">
                                      <input type="checkbox" class="form-check-input" id="option_entete" name="option_entete" [checked]="client.option_entete == 1"
                                        (change)="client.option_entete = $event.target.checked ? 1 : 0"/>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ajouter la page d'entete (contenu du mail)
                                      @if (client.option_entete != clientOrig.option_entete) {
                                        <div
                                        class="help-block text-warning blink">N'oubliez pas de sauvegarder</div>
                                      }
                                    </label>
                                  </div>
                                }
                                <hr/>
                                <div class="form-check" >
                                  <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" id="option_zip" name="option_zip" [checked]="client.option_zip == 1"
                                      (change)="client.option_zip = $event.target.checked ? 1 : 0"/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Obtenir en fin de mois, une archive ZIP des FAX reçus
                                    @if (client.option_zip != clientOrig.option_zip) {
                                      <div
                                      class="help-block text-warning blink">N'oubliez pas de sauvegarder</div>
                                    }
                                  </label>
                                </div>
                                <hr>
                                  <br>
                                    <br>
                                      <div class="d-flex flex-row">
                                        <div class="form-actions">
                                          <button type="submit" [disabled]="f.invalid || dataLoading" class="btn btn-primary"
                                            [ngClass]="{'btn-success': f.dirty, 'btn-secondary': f.invalid}" (click)="updateClient()">
                                            <i class="fa fa-save"></i>&nbsp;Sauvegarder
                                          </button>
                                          @if (dataLoading) {
                                            <img src="./assets/img/loading.gif" />
                                          }
                                        </div>
                                        <div class="btn btn-outline-secondary ml-auto" (click)="cancelEdit()">Annuler</div>
                                      </div>
                                      <br>
                                        <br>
                                        </form>
                                      }
                                    </div>
                                  </div>
                                </div>
                              }

                              @if (activeTab === 'faxline') {
                                <div class="tab-pane fade show active" id="faxline" role="tabpanel" aria-labelledby="faxline-tab"
                                  >
                                  <app-faxline [client]="this.client"></app-faxline>
                                </div>
                              }

                              @if (activeTab === 'factures') {
                                <div class="tab-pane fade show active" id="factures" role="tabpanel" aria-labelledby="factures-tab"
                                  >
                                  <app-facture [client]="this.client"></app-facture>
                                </div>
                              }

                            </div>
                          </div>

                          <app-modal [(isActive)]="showGenkeyConfirm" [overlayDismiss]="true" (overlayClick)="showGenkeyConfirm=false">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title">Clef API</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showGenkeyConfirm=false">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <p>Regénérer une clef d'API ?</p>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)='genkeyConfirm()'>Confirmer</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                                (click)="showGenkeyConfirm=false">Annuler</button>
                              </div>
                            </div>
                          </app-modal>
