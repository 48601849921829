export interface IFaxLine {
  id: number;
  customerid: number;
  faxnumber: string;
  ligneportee: string;
  name: string;
  account: string;
  authemail: string;
  notifemail: string;
  notifonlyerror: number;
  deleted: number;
  faxsend: number;
  faxrcvd: number;
  faxpages: number;
  sendingCampaignid: number;
}
export class FaxLine implements IFaxLine {
  id: number;
  customerid: number;
  faxnumber: string;
  ligneportee: string;
  name: string;
  account: string;
  authemail: string;
  notifemail: string;
  notifonlyerror: number;
  deleted: number;
  faxsend: number;
  faxrcvd: number;
  faxpages: number;
  sendingCampaignid: number;
  constructor() {
    this.id = 0;
  }
}
