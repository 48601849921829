<div class="client-view p-md-4">
  <app-client-select [selectedUser]="client"></app-client-select>

  <ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
    [fullScreen]="false">
  </ngx-spinner>

  <div class="card">
    <div class="card-body">

      <!-- <div us-spinner="{radius:30, width:8, length: 16, position:'fixed', top:'30%'}" spinner-key="spinner-3"></div> -->

      <br>
        <br>
          <h3>Mon annuaire</h3>
          <br>

            <div class="inline">
              <div class="form-group">
                <button class="btn btn-success btn-sm" (click)="addContact()"><i class="fa fa-plus"></i>&nbsp;&nbsp;Ajouter un contact</button>
              </div>
            </div>
            <br>



              <div class="clearfix">
                <form class="inline">
                  <!-- <button type="button" (click)="Export('TEXT')"
                class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
                <button type="button" (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
                <button type="button" (click)="Export('EXCEL')"
                class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
                <button type="button" (click)="Export('PRINT')"
                class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button> -->

                <div class="form-group form-inline ml-auto">
                  Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
                  [(ngModel)]="pagingService.searchTerm" />
              </div>
            </form>

            <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
              width="100%">
              <thead>
                <tr>
                  <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
                  <th class="th collapsible" sortable="company" (sort)="onSort($event)">Societé</th>
                  <th class="th collapsible" sortable="service" (sort)="onSort($event)">Service</th>
                  <th class="th collapsible" sortable="lastname" (sort)="onSort($event)">Nom</th>
                  <th class="th collapsible" sortable="firstname" (sort)="onSort($event)">Prénom</th>
                  <th class="th collapsible" sortable="faxnumber" (sort)="onSort($event)">N° de Fax</th>
                  <th class="th collapsible" sortable="telnumber" (sort)="onSort($event)">N° de Tel</th>
                  <th class="th collapsible" sortable="email" (sort)="onSort($event)">Email</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              @if (initialized) {
                <tbody>
                  @for (annuaire of annuaires$ | async; track annuaire) {
                    <tr (click)="Select(annuaire)"
                      [ngClass]="{'selected': annuaire.id === selectedAnnuaire?.id}">
                      <td>{{annuaire.id}}</td>
                      <td>
                        <ngb-highlight [result]="annuaire.company" [term]="pagingService.searchTerm"></ngb-highlight>
                      </td>
                      <td class="collapsible">
                        <ngb-highlight [result]="annuaire.service" [term]="pagingService.searchTerm"></ngb-highlight>
                      </td>
                      <td>
                        <ngb-highlight [result]="annuaire.lastname" [term]="pagingService.searchTerm"></ngb-highlight>
                      </td>
                      <td class="collapsible">
                        <ngb-highlight [result]="annuaire.firstname" [term]="pagingService.searchTerm"></ngb-highlight>
                      </td>
                      <td>
                        <ngb-highlight [result]="annuaire.faxnumber" [term]="pagingService.searchTerm"></ngb-highlight>
                      </td>
                      <td class="collapsible">
                        <ngb-highlight [result]="annuaire.telnumber" [term]="pagingService.searchTerm"></ngb-highlight>
                      </td>
                      <td class="collapsible">
                        <ngb-highlight [result]="annuaire.email" [term]="pagingService.searchTerm"></ngb-highlight>
                      </td>
                      <td>
                        <div class="inline">
                          <button type="button" title="Editer" (click)="Edit(annuaire)"
                            class="btn btn-sm btn-primary ml-1 w30"><i class="fa fa-edit"></i></button>
                            <button type="button" title="Supprimer" (click)="Delete(annuaire)"
                              class="btn btn-sm btn-danger ml-1 w30"><i class="fa fa-trash-o"></i></button>
                            </div>
                          </td>
                        </tr>
                      }
                    </tbody>
                  }
                </table>

                <div class="inline">
                  <div class="">
                    Total: {{(total$ | async)}}
                  </div>
                  <div class="ml-auto">
                    <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
                      [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
                      [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <app-modal [(isActive)]="showDeleteConfirm" [overlayDismiss]="true" (overlayClick)="showDeleteConfirm=false">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Suppression de l'annuaire</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showDeleteConfirm=false">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Confirmez-vous la suppression de ce contact ?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)='DeleteConfirm()'>Confirmer</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal"
              (click)="showDeleteConfirm=false">Annuler</button>
            </div>
          </div>
        </app-modal>

        <app-modal [(isActive)]="showEditAnnuaire" [overlayDismiss]="false" [isLarge]="true">
          <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{selectedAnnuaire.id === 0 ? 'Ajout' : 'Edition'}} contact</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showEditAnnuaire=false">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form role="form" name="form">
                  <div class="form-group">
                    <label for="faxnumber">Compagnie</label>
                    <input type="text" name="company" id="company" class="form-control" [(ngModel)]="selectedAnnuaire.company"
                      autocomplete="off" autocapitalize="off" autocorrect="off" required />
                  </div>
                  <div class="form-group">
                    <label for="faxnumber">Service</label>
                    <input type="text" name="service" id="service" class="form-control" [(ngModel)]="selectedAnnuaire.service"
                      autocomplete="off" autocapitalize="off" autocorrect="off" required />
                  </div>
                  <div class="form-group">
                    <label for="faxnumber">Nom</label>
                    <input type="text" name="lastname" id="lastname" class="form-control"
                      [(ngModel)]="selectedAnnuaire.lastname" autocomplete="off" autocapitalize="off" autocorrect="off"
                      required />
                  </div>
                  <div class="form-group">
                    <label for="faxnumber">Prénom</label>
                    <input type="text" name="firstname" id="firstname" class="form-control"
                      [(ngModel)]="selectedAnnuaire.firstname" autocomplete="off" autocapitalize="off" autocorrect="off"
                      required />
                  </div>
                  <div class="form-group">
                    <label for="faxnumber">N° de Fax</label>
                    <input type="text" name="faxnumber" id="faxnumber" class="form-control"
                      [(ngModel)]="selectedAnnuaire.faxnumber" autocomplete="off" autocapitalize="off" autocorrect="off"
                      required />
                  </div>
                  <div class="form-group">
                    <label for="faxnumber">N° de Tél</label>
                    <input type="text" name="telnumber" id="telnumber" class="form-control"
                      [(ngModel)]="selectedAnnuaire.telnumber" autocomplete="off" autocapitalize="off" autocorrect="off"
                      required />
                  </div>
                  <div class="form-group">
                    <label for="faxnumber">Email</label>
                    <input type="text" name="email" id="email" class="form-control" [(ngModel)]="selectedAnnuaire.email"
                      autocomplete="off" autocapitalize="off" autocorrect="off" required />
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary"
                (click)='UpdateAnnuaire()'>{{ selectedAnnuaire.id === 0 ? 'Ajouter' : 'Modifier'}}</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                (click)="showEditAnnuaire=false">Annuler</button>
              </div>
            </div>
          </div>
        </app-modal>
