import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';

import { UserLoginComponent } from './components/login/user-login.component';
import { AdminLoginComponent } from './components/login/admin-login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ClientComponent } from './components/client/client.component';
import { ClientEditorComponent } from './components/client-editor/client-editor.component';
import { CreditHistoryComponent } from './components/credit-history/credit-history.component';
import { PasswordRequestComponent } from './components/password-request/password-request.component';
import { NousContacterComponent } from './components/nous-contacter/nous-contacter.component';
import { AchatCreditComponent } from './components/achat-credit/achat-credit.component';
import { CampaignListComponent } from './components/campaign-list/campaign-list.component';
import { CampaignComponent } from './components/campaign/campaign.component';
import { ContactListComponent } from './components/contact-list/contact-list.component';
import { ContactComponent } from './components/contact/contact.component';
import { ContactListUploadComponent } from './components/contact-list-upload/contact-list-upload.component';
import { CreditAdminComponent } from './components/credit-admin/credit-admin.component';
import { AdminManagerComponent } from './components/admin-manager/admin-manager.component';
import { FaxLinesComponent } from './components/faxlines/faxlines.component';
import { AnnuaireComponent } from './components/annuaire/annuaire.component';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'logout',
        component: LogoutComponent
      },
      {
        path: 'client/selection',
        component: ClientComponent
      },
      {
        path: 'client/selection',
        component: ClientComponent
      },
      {
        path: 'client/edition',
        component: ClientEditorComponent
      },
      {
        path: 'client/credit',
        component: CreditHistoryComponent
      },
      {
        path: 'client/lignesfax',
        component: FaxLinesComponent
      },
      {
        path: 'client/annuaire',
        component: AnnuaireComponent
      },
      {
        path: 'nous_contacter',
        component: NousContacterComponent,
      },
      {
        path: 'client/achat',
        component: AchatCreditComponent,
      },
      {
        path: 'fax/:status',
        component: CampaignListComponent,
      },
      {
        path: 'campagne/creation',
        component: CampaignComponent,
      },
      {
        path: 'contact/list',
        component: ContactListComponent,
      },
      {
        path: 'contact/charger',
        component: ContactListUploadComponent,
      },
      {
        path: 'contact/affichage',
        component: ContactComponent,
      },
      {
        path: 'contact/ajouter',
        component: ContactComponent,
      },
      {
        path: 'credit/admin',
        component: CreditAdminComponent,
        canActivate: [AdminGuard]
      },
      {
        path: 'admin/manager',
        component: AdminManagerComponent,
        canActivate: [AdminGuard]
      },
    ]
  },
  {
    path: 'login',
    component: UserLoginComponent
  },
  {
    path: 'admin',
    component: AdminLoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'password_request/:isAdmin/:userName',
    component: PasswordRequestComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
