/// <reference types="@angular/localize" />

import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPrintModule } from 'ngx-print';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPaginationModule } from 'ngx-pagination';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DecimalPipe, LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { ErrorInterceptor } from './app/guards/error.interceptor';
import { BasicAuthInterceptor } from './app/guards/basic-auth.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { ExportDataService } from './app/providers/export-data.service';
import { ContactListService } from './app/providers/contact-list.service';
import { CampaignService } from './app/providers/campaign.service';
import { UserService } from './app/providers/user.service';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(CommonModule, FormsModule, BrowserModule, ReactiveFormsModule, AppRoutingModule, NgbModule, BrowserModule, AppRoutingModule, FormsModule, ReactiveFormsModule, RouterModule, NgxPaginationModule, ClipboardModule, NgxPrintModule, NgxSpinnerModule, ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            countDuplicates: true,
            resetTimeoutOnDuplicate: true,
            newestOnTop: false,
            closeButton: true,
        }), 
        // NgxStripeModule.forRoot(environment.stripePublicKey),
        // Papa,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })),
        UserService,
        CampaignService,
        ContactListService,
        ExportDataService,
        { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        DecimalPipe,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
  .then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.register('./ngsw-worker.js');
    }
  })
  .catch(err => console.error(err));
