<div class="client-view p-md-4">

  <app-client-select [selectedUser]="client"></app-client-select>

  <ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
    [fullScreen]="false">
  </ngx-spinner>

  <div class="card">
    @if (client) {
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6 col-xs-12">
            <div class="alert alert-success" role="alert">
              <h2>Crédits en cours: {{client.faxcredits}}</h2>
            </div>
            @if (isAdmin) {
              <div class="row text-center">
                <div class="btn btn-success" (click)="AddFaxPack()">
                Achat Pack</div>
              </div>
            }
          </div>
          <div class="col-sm-6 col-xs-12">
            <div class="alert alert-info">
              <h2>Totaux:</h2>
              <ul>
                <li>{{client.faxpages}} pages dans {{client.faxsend}} fax émis</li>
                <li>{{client.faxrcvd}} fax reçus</li>
              </ul>
            </div>
          </div>
        </div>
        <br/>
        <h3>Consommations mensuelles</h3>
        <div class="text-center canvas">
          <canvas id="canvas">{{ chart }}</canvas>
        </div>
        <div style="clear:both"></div>
        <br>
          <hr>
            <br>
              <h3>Historique</h3>
              <form>
                <div class="row">
                  <div class="form-group col-4">
                    <label for="startdate">Date de début de période</label>
                    <div class="input-group">
                      <input class="form-control" placeholder="yyyy-mm-dd" name="startdate" [(ngModel)]="period.startdate"
                        ngbDatepicker #d1="ngbDatepicker" (dateSelect)="checkDate()" (click)="d1.toggle()">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary btn-grey" (click)="d1.toggle()" type="button"><i
                        class="fa fa-calendar"></i></button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <label for="stopdate">Date de fin</label>
                    <div class="input-group">
                      <input class="form-control" placeholder="yyyy-mm-dd" name="stopdate" [(ngModel)]="period.stopdate"
                        ngbDatepicker #d2="ngbDatepicker" (dateSelect)="checkDate()" (click)="d2.toggle()">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary btn-grey" (click)="d2.toggle()" type="button"><i
                        class="fa fa-calendar"></i></button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <button class="btn" style="margin-top: 32px;"
                      [ngClass]="{'btn-success blink':dateChanged, 'btn-info': !dateChanged}"
                    (click)="selectPeriod()">Rechercher</button>
                  </div>
                </div>
              </form>
              @if (isAdmin) {
                <div style="margin-left:25px;font-size:16pw">
                  <div class="row" style="margin-left:25px">
                    <div class="col-xs-8 label label-success">Crédits FAX consommés {{consoPeriod?.totalFaxCredits.faxcredits[0]}}</div>
                  </div>
                </div>
              }
              <br>
                <br>
                  <div class="p-20">
                    <div class="clearfix">
                      <form class="inline">
                        <button type="button" (click)="Export('TEXT')"
                        class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
                        <button type="button" (click)="Export('CSV')"
                        class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
                        <button type="button" (click)="Export('EXCEL')"
                        class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
                        <button type="button" (click)="Export('PRINT')"
                        class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button>
                        <div class="form-group form-inline ml-auto">
                          Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
                          [(ngModel)]="pagingService.searchTerm" />
                      </div>
                    </form>
                    <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
                      width="100%">
                      <thead>
                        <tr>
                          <th class="th" sortable="creditid" (sort)="onSort($event)">ID</th>
                          <th class="th" sortable="campaignid" (sort)="onSort($event)">Campagne</th>
                          <th class="th collapsible" sortable="history" (sort)="onSort($event)">Origine</th>
                          <th class="th collapsible" sortable="label" (sort)="onSort($event)">Achat / Campagne</th>
                          <th class="th" sortable="dateoperation" (sort)="onSort($event)">Date</th>
                          <th class="th" sortable="faxcredits" (sort)="onSort($event)">Crédits</th>
                        </tr>
                      </thead>
                      @if (initialized) {
                        <tbody>
                          @for (credit of credits$ | async; track credit) {
                            <tr (click)="selectCredit(credit)"
                              [ngClass]="{'selected': credit.creditid === selectedCredit?.creditid}">
                              <td>{{credit.creditid}}</td>
                              <td>
                                <ngb-highlight [result]="credit.campaignid" [term]="pagingService.searchTerm">
                                </ngb-highlight>
                              </td>
                              <td>
                                <ngb-highlight [result]="credit.history === '0' ? '' : credit.history" [term]="pagingService.searchTerm">
                                </ngb-highlight>
                              </td>
                              <td class="collapsible">
                                <ngb-highlight [result]="credit.label" [term]="pagingService.searchTerm">
                                </ngb-highlight>
                              </td>
                              <td>
                                <ngb-highlight [result]="credit.dateoperation" [term]="pagingService.searchTerm">
                                </ngb-highlight>
                              </td>
                              <td class="text-success"
                                [ngClass]="{'text-right text-danger': credit.faxcredits < 0}">
                                {{credit.faxcredits}}
                              </td>
                            </tr>
                          }
                        </tbody>
                      }
                    </table>
                    <div class="inline">
                      <div class="">
                        Total: {{(total$ | async)}}
                      </div>
                      <div class="ml-auto">
                        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
                          [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
                          [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
                        </ngb-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

          </div>

        </div>

        <app-modal [(isActive)]="showAddCredit" [overlayDismiss]="true" [isLarge]="true" (overlayClick)="showAddCredit=false">
          <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Ajout crédits FAX</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showAddCredit=false">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form role="form" name="form">

                  <div class="form-group">
                    <label>Saisissez le nombre de crédits</label>
                    <input type="number" name="credits" class="form-control" [(ngModel)]="credits"
                      autocomplete="off" autocapitalize="off" autocorrect="off"
                      required/>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)='AddCredits()'>Ajouter</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                (click)="showAddCredit=false; credits=0">Annuler</button>
              </div>
            </div>
          </div>
        </app-modal>

