import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgClass } from '@angular/common';


@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class ModalComponent implements OnInit {

  @Input() overlayDismiss = true;
  @Input() isActive = false;
  @Input() isLarge = false;
  @Input() isXLarge = false;
  hasOverlay = true;
  @Output() overlayClick: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor(
    public activeModal: NgbModal
  ) {}
  ngOnInit() {
    this.isActive = false;
  }

  openModal() {
    this.isActive = true;
  }

  closeModal() {
    this.isActive = false;
  }

  handleOverlayClick() {
    console.log('overlayClose');
    if (this.overlayDismiss) {
      this.closeModal();
      this.overlayClick.emit(true);
    }
  }

}
