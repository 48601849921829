import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { IUser } from 'src/app/interfaces/user';
import { UserService } from 'src/app/providers/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { MailService } from 'src/app/providers/mail.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
// import { StripeCardComponent, ElementOptions, ElementsOptions, StripeService, StripeJS } from '@nomadreservations/ngx-stripe';
import * as R from 'ramda';
import { environment } from '../../../environments/environment';
import { FormsModule } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

declare var Stripe: any;

@Component({
    selector: 'app-achat-credit',
    templateUrl: './achat-credit.component.html',
    styleUrls: ['./achat-credit.component.scss'],
    standalone: true,
    imports: [NgxSpinnerModule, FormsModule, DecimalPipe]
})
export class AchatCreditComponent implements OnInit, OnDestroy, AfterViewInit {

  site = 0;
  siteicon = [ './assets/img/ifaxpro.png', './assets/img/tims-logo.png', './assets/img/gedis-logo.png' ];


  client: IUser;
  userSubscribe = null;
  initialized = true;

  contactmail: any = {};
  faxpacks = null;

  codepromo = { label: '', premium: 0.045, lowcost: 0.035, tentative: '' };
  payment = { tab: 0, loadingPrice: false, id: 0, codepromo: '', product: '', price: 0, qty: 0, tva: 20, total: 0.0, totalht: 0, totalttc: 0 };
  submittedPayment = false;

  activeTab = 'premium';
  customQty = 0;
  customQtyChanged: Subject<number> = new Subject<number>();

  @ViewChild('payElement', {static: true }) payElement: any;

  elements = null;
  cardElement = null;
  clientSecret = '';
  intentId = '';

  // tslint:disable-next-line: variable-name
  stripeErrorCodes = [
    {  code: 'incorrect_number'                     , message: 'Le numéro de carte est incorrect.', },
    {  code: 'invalid_number'                       , message: 'Le numéro de carte n\'est pas valide.', },
    {  code: 'invalid_expiry_month'                 , message: 'Le mois d\'expiration est invalide.', },
    {  code: 'invalid_expiry_year'                  , message: 'L\'année d\'expiration est invalide.', },
    {  code: 'invalid_cvc'                          , message: 'Le code de vérifiactin est invalide.', },
    {  code: 'expired_card'                         , message: 'La carte a expirée.', },
    {  code: 'incorrect_cvc'                        , message: 'Le code de sécurité est incorrect.', },
    {  code: 'incorrect_zip'                        , message: 'La vérification du code ZIP a échoué.', },
    {  code: 'card_declined'                        , message: 'Cette carte est invalide.', },
    {  code: 'missing'                              , message: 'Ce client n\'a pas de carte.', },
    {  code: 'processing_error'                     , message: 'Une erreur est survenu lors du paiement.', },
    {  code: 'rate_limit'                           , message: 'Trop de requêtes ont été faites simultanément.'},
    {  code: 'payment_intent_authentication_failure', message: 'Nous ne pouvons pas authentifier votre mode de paiement... Choisissez un autre mode de paiement et réessayez.'},
    {  code: 'payment_intent_incompatible_payment_method', message: 'Le paiement s\'attend à un mode de paiement avec des propriétés différentes de celles fournies.', },
    {  code: 'payment_intent_invalid_parameter', message: 'Un ou plusieurs paramètres fournis n\'étaient pas autorisés', },
    {  code: 'payment_intent_payment_attempt_failed', message: 'La dernière tentative de paiement a échoué.', },
    {  code: 'payment_intent_unexpected_state', message: 'L\'état du paiement était incompatible avec l’opération que vous tentiez d’exécuter.', },

    { code: 'authentication_required', message: 'La carte a été refusée car la transaction nécessite une authentification.' },
    { code: 'approve_with_id', message: 'Le paiement ne peut être autorisé.' },
    { code: 'call_issuer', message: 'La carte a été refusée pour une raison inconnue.' },
    { code: 'card_not_supported', message: 'La carte ne prend pas en charge ce type d’achat.' },
    { code: 'card_velocity_exceeded', message: 'Le client a dépassé le solde ou la limite de crédit disponible sur sa carte.' },
    { code: 'currency_not_supported', message: 'La carte ne prend pas en charge la devise spécifiée.' },
    { code: 'do_not_honor', message: 'La carte a été refusée pour une raison inconnue.' },
    { code: 'do_not_try_again', message: 'La carte a été refusée pour une raison inconnue.' },
    { code: 'duplicate_transaction', message: 'Une transaction avec un montant et des informations de carte de crédit identiques a été soumise très récemment.' },
    { code: 'fraudulent', message: 'Le paiement a été refusé car Stripe le soupçonne d’être frauduleux.' },
    { code: 'generic_decline', message: 'La carte a été refusée pour une raison inconnue.' },
    { code: 'incorrect_pin', message: 'Le code PIN entré est incorrect. Ce code de refus ne s\'applique qu\'aux paiements effectués avec un lecteur de carte.' },
    { code: 'insufficient_funds', message: 'La carte ne dispose pas de fonds suffisants pour effectuer l\'achat.' },
    { code: 'invalid_account', message: 'La carte ou le compte auquel la carte est connectée est invalide.' },
    { code: 'invalid_amount', message: 'Le montant du paiement est invalide ou dépasse le montant autorisé.' },
    { code: 'invalid_cvc', message: 'Le numéro CVC est incorrect.' },
    { code: 'invalid_expiry_year', message: 'L\'année d\'expiration invalide.' },
    { code: 'invalid_number', message: 'Le numéro de carte est incorrect.' },
    { code: 'invalid_pin', message: 'Le code PIN entré est incorrect. Ce code de refus ne s\'applique qu\'aux paiements effectués avec un lecteur de carte.' },
    { code: 'issuer_not_available', message: 'L\'émetteur de la carte n\'a pas pu être contacté et le paiement n\'a donc pas pu être autorisé.' },
    { code: 'lost_card', message: 'Le paiement a été refusé car la carte est perdue.' },
    { code: 'merchant_blacklist', message: 'Le paiement a été refusé car il correspond à une valeur de la liste de blocage de l\'utilisateur Stripe.' },
    { code: 'new_account_information_available', message: 'La carte ou le compte auquel la carte est connectée est invalide.' },
    { code: 'no_action_taken', message: 'La carte a été refusée pour une raison inconnue.' },
    { code: 'not_permitted', message: 'Le paiement n\'est pas autorisé.' },
    { code: 'pickup_card', message: 'La carte ne peut pas être utilisée pour effectuer ce paiement (il est possible qu’elle ait été perdue ou volée).' },
    { code: 'pin_try_exceeded', message: 'Le nombre autorisé d\'essais de code PIN a été dépassé.' },
    { code: 'processing_error', message: 'Une erreur s\'est produite lors du traitement de la carte.' },
    { code: 'reenter_transaction', message: 'Le paiement n\'a pas pu être traité par l\'émetteur pour une raison inconnue.' },
    { code: 'restricted_card', message: 'La carte ne peut pas être utilisée pour effectuer ce paiement (il est possible qu’elle ait été perdue ou volée).' },
    { code: 'revocation_of_all_authorizations', message: 'La carte a été refusée pour une raison inconnue.' },
    { code: 'revocation_of_authorization', message: 'La carte a été refusée pour une raison inconnue.' },
    { code: 'security_violation', message: 'La carte a été refusée pour une raison inconnue.' },
    { code: 'service_not_allowed', message: 'La carte a été refusée pour une raison inconnue.' },
    { code: 'stolen_card', message: 'Le paiement a été refusé car la carte est volée.' },
    { code: 'stop_payment_order', message: 'La carte a été refusée pour une raison inconnue.' },
    { code: 'testmode_decline', message: 'Un numéro de carte de test Stripe a été utilisé.' },
    { code: 'transaction_not_allowed', message: 'La carte a été refusée pour une raison inconnue.' },
    { code: 'try_again_later', message: 'La carte a été refusée pour une raison inconnue.' },
    { code: 'withdrawal_count_limit_exceeded', message: 'Le client a dépassé le solde ou la limite de crédit disponible sur sa carte.' },

    { code: 'incomplete_zip', message: 'Le code postal est invalide' },
    { code: 'incomplete_cvc', message: 'Le code de sécurité est invalide' },
    { code: 'incomplete_number', message: 'Le numéro de carte est invalide' },
    { code: 'invalid_expiry_year_past', message: 'La date d\'expiration est dans le passé' },

  ];

  // @ViewChild(StripeCardComponent, { static: false }) card: StripeCardComponent;

  // cardOptions: ElementOptions = {
  //   style: {
  //     base: {
  //       iconColor: '#222222',
  //       color: '#222222',
  //       lineHeight: '40px',
  //       fontWeight: 300,
  //       fontSize: '18px',
  //       '::placeholder': {
  //         color: '#777777'
  //       }
  //     }
  //   }
  // };
  // elementsOptions: ElementsOptions = {
  //   locale: 'fr'
  // };

  username = '';
  showPayment = false;
  stripeName = '';
  error = undefined;
  complete = true;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private mailService: MailService,
    private router: Router,
    // private stripeService: StripeService,
    // private stripeJS: StripeJS,
  ) {

    if (window.location.origin.indexOf('fax.tims.fr') !== -1) {
      this.site = 1;
    } else if (window.location.origin.indexOf('fax.gedistelecom.com') !== -1) {
      this.site = 2;
    }

    this.userSubscribe = this.userService.selectedUser$.subscribe(user => {
      if (user) {
        this.client = user;
        this.username = R.clone(this.client.username);
        this.contactmail = {
          id: this.client.id,
          admin: this.client.admin,
          email_admin: this.client.email_admin,
          name: this.client.name + ' ' + this.client.firstname,
          company: this.client.company,
          username: this.client.username,
          subject: '',
          message: ''
        };
      }

    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    console.log('init stripe component');
  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
  }

  selectTab(tab) {
    this.activeTab = tab;
  }

  SendMail() {
    this.spinner.show();
    this.mailService.Send(this.contactmail).subscribe((response: any) => {

      if (response.success) {
        this.toastr.success('Votre message a été envoyé', '');
        this.router.navigate(['/']);
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.spinner.hide();

    });
  }

  validateCodePromo() {}

  recomputePrice(qty) {
    // this.customQtyChanged.next(qty);
  }


  selectPack(pack) {
      // if (this.client.disabled === '0') {

      //   this.spinner.show();

      //   this.payment.price = pack.unitprice;
      //   this.payment.qty = pack.qty;
      //   this.payment.codepromo = this.codepromo.label;
      //   this.payment.product = (this.activeTab === 'premium') ? 'SMS premium' : 'SMS lowcost';
      //   // this.stripePaiement();

      //   this.paymentService.CreateAchat(this.client.id, this.payment, !environment.production).subscribe((response: any) => {
      //     if (response.success) {
      //       this.showAchatSend = true;
      //     }
      //     this.spinner.hide();
      //   });

      // }
  }

}

