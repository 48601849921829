
<div class="p-20">
  <div class="clearfix">
    <form class="inline">
      <button type="button" (click)="Export('TEXT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
      <button type="button" (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
      <button type="button" (click)="Export('EXCEL')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
      <!-- <button type="button" (click)="Export('PRINT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button> -->
    </form>
    <br>

      <table id="selectedColumn" class="table table-striped table-hover table-sm" cellspacing="0"
        width="100%">
        <thead>
          <tr>
            <th class="th">Mois</th>
            <th class="th">Total</th>
            <th class="th">Crédits ajoutés</th>
            <th class="th">Postpaid</th>
          </tr>
        </thead>
        <tbody>
          @for (c of conso; track c) {
            <tr>
              <th>{{c.date}}</th>
              <th>{{c.total}}</th>
              <td>{{c.faxcredits}}</td>
              <td>{{c.postpaid}} @if (c.postpaidC>0) {
                <span>({{c.postpaidC}})</span>
              }</td>
            </tr>
          }
        </tbody>
      </table>

    </div>
  </div>
