<div class="client-view p-md-4">

  <ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
    [fullScreen]="false">
  </ngx-spinner>


  <div class="card">
    <div class="card-body">

      <div class="d-flex">
        <h3>{{contactListService?.selectedCList?.name}}</h3>
        <div class="ml-auto btn btn-info" (click)="back()">Retour</div>
      </div>

      <p>
        {{contactListService?.selectedCList?.fields}}
      </p>

      <form class="form-inline">
        <div class="form-group">
          <div class="btn btn-success" (click)="ShowInsertContact()">
            <i class="fa fa-plus"></i>&nbsp;Ajouter un contact</div>
          </div>
          <div class="ml-auto form-group">
            <div class="btn btn-primary" (click)="setAllContact(1)">Tout activer</div>
            <div class="btn btn-danger" (click)="setAllContact(0)">Tout désactiver</div>
          </div>
        </form>
        <br>


          <div class="clearfix">
            <form class="inline">
              <div (click)="Export('TEXT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</div>
              <div (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</div>
              <div (click)="Export('EXCEL')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</div>
              <div (click)="Export('PRINT')" class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</div>

              <div class="form-group form-inline ml-auto">
                Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
                [(ngModel)]="pagingService.searchTerm" />
            </div>
          </form>

          <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
            width="100%">
            <thead>
              <tr>
                <th class="th">ID</th>
                <th class="th">List ID</th>
                <th class="th" sortable="json_data" (sort)="onSort($event)">Données</th>
                <th class="th" sortable="selected" (sort)="onSort($event)">Sélectionné</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            @if (initialized) {
              <tbody>
                @for (c of contacts$ | async; track c) {
                  <tr (click)="selectContact($event)"
                    [ngClass]="{'selected': c.id === selectedContact?.id}">
                    <td>{{c.id}}</td>
                    <td>{{c.listid}}</td>
                    <td>
                      <ngb-highlight [result]="c.json_data" [term]="pagingService.searchTerm"></ngb-highlight>
                    </td>
                    <td>{{c.selected}}</td>
                    <td>
                      <div class="inline">
                        <div title="Ignorer" (click)="clearContact(c)" class="btn btn-sm btn-info ml-1 w30"><i class="fa fa-minus"></i></div>
                        <div title="Activer" (click)="setContact(c)" class="btn btn-sm btn-primary ml-1 w30"><i class="fa fa-plus"></i></div>
                      </div>
                    </td>
                  </tr>
                }
              </tbody>
            }
          </table>

          <div class="inline">
            <div class="">
              Total: {{(total$ | async)}}
            </div>
            <div class="ml-auto">
              <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
                [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
                [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>

  <app-modal [(isActive)]="showAddContact" [overlayDismiss]="true" (overlayClick)="showAddContact=false" [isXLarge]="true">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Ajout d'un contact</h5>
        <div class="close" data-dismiss="modal" aria-label="Close" (click)="showAddContact=false">
          <span aria-hidden="true">&times;</span>
        </div>
      </div>

      <div class="modal-body">
        <div class="mb-3">Veuillez saisir les champs pour votre contact (Format en +336xxx ou +337xxx pour le numéro)</div>
        <hr>

          <form role="form" name="form" class="scrollable">
            @for (f of fields; track f; let i = $index) {
              <div class="form-group">
                <label for="f{{i}}">{{f.label}}</label>
                <input type="text" name="f[i]" id="f[i]" class="form-control"
                  [(ngModel)]="f.value"
                  autocomplete="off" autocapitalize="off" autocorrect="off">
              </div>
            }
          </form>
        </div>

        <div class="modal-footer">
          <div class="btn btn-primary" (click)='InsertContact()'>Ajouter</div>
          <div class="btn btn-secondary" data-dismiss="modal" (click)="showAddContact=false">Annuler</div>
        </div>
      </div>
    </app-modal>
