<div class="client-view p-md-4">
  <ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
    [fullScreen]="false">
  </ngx-spinner>

  <app-client-select [selectedUser]="client"></app-client-select>



  <div class="card">
    <div class="card-body">

      <ul class="nav nav-pills mb-n3" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': activeTab===1}" id="tabrcv" data-toggle="tab" (click)="selectTabFromStatus('recus')"
          role="tab" aria-controls="en cours" aria-selected="false">FAX reçus</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': activeTab===0}" id="tabsent" data-toggle="tab" (click)="selectTabFromStatus('envoyes')"
          role="tab" aria-controls="en préparation" aria-selected="true">FAX envoyés</a>
        </li>
      </ul>
      <hr>

        <div class="clearfix">

          @if (activeTab===0) {
            <button class="btn btn-success" (click)="AddCampaign()">
              <i class="fa fa-plus"></i>&nbsp;Envoyer un FAX
            </button>
          }

          @if (activeTab===1) {
            <button class="btn btn-success" ng-click="ArchiveFax()">
              <i class="fa fa-zip"></i>&nbsp;Télécharger une archive
            </button>
          }
          <br>
            <br>

              <form class="inline">
                <button type="button" (click)="Export('TEXT')"
                class="btn btn-sm btn-outline-secondary ml-2 h-100">Copy</button>
                <button type="button" (click)="Export('CSV')" class="btn btn-sm btn-outline-secondary ml-2 h-100">CSV</button>
                <button type="button" (click)="Export('EXCEL')"
                class="btn btn-sm btn-outline-secondary ml-2 h-100">Excel</button>
                <button type="button" (click)="Export('PRINT')"
                class="btn btn-sm btn-outline-secondary ml-2 h-100">Print</button>

                <div class="form-group form-inline ml-auto">
                  Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
                  [(ngModel)]="pagingService.searchTerm" />
                <!-- <span class="ml-3" *ngIf="loading$ | async">Chargement...</span> -->
              </div>
            </form>
            <table id="selectedColumn" class="table table-bordered table-striped table-hover table-sm" cellspacing="0"
              width="100%">
              <thead>
                @if (activeTab===0) {
                  <tr>
                    <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
                    <th class="th" sortable="name" (sort)="onSort($event)">Document</th>
                    @if (isAdmin) {
                      <th class="th" sortable="dateReception" (sort)="onSort($event)">Création</th>
                    }
                    <th class="th" sortable="dateStart" (sort)="onSort($event)">Début envoi</th>
                    <th class="th" sortable="dateEnd" (sort)="onSort($event)">Date statut</th>
                    <th class="th" sortable="status" (sort)="onSort($event)">Statut</th>
                    <th class="th collapsible" >Total / OK / Erreur</th>
                    <th class="th collapsible" sortable="destnumber" (sort)="onSort($event)">N° dest.</th>
                    <th class="th collapsible" sortable="destname" (sort)="onSort($event)">Nom dest.</th>
                    <th class="th collapsible" sortable="faxnumber" (sort)="onSort($event)">Envoyé par le n°</th>
                    <th class="th collapsible" sortable="pages" (sort)="onSort($event)">#Pages</th>
                    <th class="th">&nbsp;</th>
                  </tr>
                }
                @if (activeTab===1) {
                  <tr>
                    <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
                    <th class="th">Document</th>
                    <th class="th" sortable="dateEnd" (sort)="onSort($event)">Date de réception</th>
                    <th class="th collapsible" sortable="fromnumber" (sort)="onSort($event)">Emetteur</th>
                    <th class="th collapsible" sortable="fromname" (sort)="onSort($event)">Identifiant émetteur</th>
                    <th class="th collapsible" sortable="faxnumber" (sort)="onSort($event)">Reçu sur le n°</th>
                    <th class="th">&nbsp;</th>
                  </tr>
                }
              </thead>


              @if (initialized && activeTab===0) {
                <tbody>
                  @for (c of campaigns$ | async; track c) {
                    <tr (click)="selectCampaign(c)"
                      [ngClass]="{'selected': c.id === selectedCampaign?.id, 'redClass': c.deleted === '1'}">
                      <td>{{c.id}}</td>
                      <td>
                        <span style='cursor:zoom-in' (click)="openPDF(c, 0)">
                          <img [src]="c.fileimg" width='20'>
                          &nbsp;&nbsp;{{c.name}}
                        </span>
                      </td>
                      @if (isAdmin) {
                        <td>
                          <ngb-highlight [result]="c.dateReception" [term]="pagingService.searchTerm">
                          </ngb-highlight>
                        </td>
                      }
                      <td>
                        <ngb-highlight [result]="c.dateStart" [term]="pagingService.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td>
                        <ngb-highlight [result]="c.dateEnd" [term]="pagingService.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td>
                        @if (c.retry > 0) {
                          <div>
                            <b>{{(1*c.retry) + 1}}<sup>ème</sup>&nbsp;essai</b><br>
                          </div>
                        }
                        @if (c.retry==0 && c.status === 'error') {
                          <div>
                            <b>1<sup>er</sup>&nbsp;essai</b><br>
                          </div>
                        }
                        @if (c.deleted === '1') {
                          <span> Supprimé - état précédent: </span>
                        }
                        @if (c.status==='doing') {
                          <span class="text-info"><i class="fa fa-sign-out"></i>&nbsp;En cours d'envoi</span>
                        }
                        @if (c.status==='todo') {
                          <span class="text-info"><i class="fa fa-gear"></i>&nbsp;En file d'attente</span>
                        }
                        @if (c.status==='done') {
                          <span class="text-success"><i class="fa fa-send"></i>&nbsp;Expédié</span>
                        }
                        @if (c.status==='error') {
                          <span class="text-danger"><i class="fa fa-exclamation"></i>&nbsp;Erreur</span>
                        }
                        @if (c.status!=='doing' && c.status!=='todo' && c.status!=='done' && c.status!=='error') {
                          <span class="text-warning"><i class="fa fa-question"></i>&nbsp;{{c.status}}</span>
                        }
                      </td>
                      <td class="collapsible">
                        {{c.countTotal + '&nbsp;/&nbsp;' + c.countSuccess + '&nbsp;/&nbsp;' + c.countFailed}}
                      </td>
                      <td class="collapsible">
                        <ngb-highlight [result]="c.destnumber" [term]="pagingService.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td class="collapsible">
                        <ngb-highlight [result]="c.destname" [term]="pagingService.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td class="collapsible">
                        <ngb-highlight [result]="c.faxnumber" [term]="pagingService.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td class="collapsible">
                        <ngb-highlight [result]="c.pages" [term]="pagingService.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td>
                        @if (c.deleted === '0' && c.status === 'error') {
                          <button type="button" title="Renvoyer" (click)="Resend(c)" class="btn btn-sm btn-warning ml-1 w30"><i class="fa fa-clone"></i></button>
                        }
                        @if (c.deleted === '0') {
                          <button type="button" title="Supprimer" (click)="Delete(c)" class="btn btn-sm btn-danger ml-1 w30"><i class="fa fa-trash-o"></i></button>
                        }
                      </td>
                    </tr>
                  }
                </tbody>
              }


              @if (initialized && activeTab==1) {
                <tbody>
                  @for (c of campaigns$ | async; track c) {
                    <tr (click)="selectCampaign(c)"
                      [ngClass]="{'selected': c.id === selectedCampaign?.id, 'redClass': c.deleted === '1'}">
                      <td>{{c.id}}</td>
                      <td>
                        <span style='cursor:zoom-in' (click)="openPDF(c, 1)">
                          <img [src]="c.fileimg" width='20'>
                          &nbsp;&nbsp;{{c.name}}
                        </span>
                      </td>
                      <td>
                        <ngb-highlight [result]="c.dateEnd" [term]="pagingService.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td class="collapsible">
                        <ngb-highlight [result]="c.fromnumber" [term]="pagingService.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td class="collapsible">
                        <ngb-highlight [result]="c.fromname" [term]="pagingService.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td class="collapsible">
                        <ngb-highlight [result]="c.faxnumber" [term]="pagingService.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td>
                        @if (c.deleted === '0') {
                          <button type="button" title="Supprimer" (click)="Delete(c)" class="btn btn-sm btn-danger ml-1 w30"><i class="fa fa-trash-o"></i></button>
                        }
                      </td>
                    </tr>
                  }
                </tbody>
              }
            </table>

            <div class="inline">
              <div class="">
                Total: {{(total$ | async)}}
              </div>
              <div class="ml-auto">
                <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
                  [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
                  [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
                </ngb-pagination>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>


    <app-modal [(isActive)]="showDeleteConfirm" [overlayDismiss]="true" (overlayClick)="showDeleteConfirm=false">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Suppression de la campagne</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showDeleteConfirm=false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Confirmez-vous la suppression de cette campagne ?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)='DeleteConfirm()'>Confirmer</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal"
          (click)="showDeleteConfirm=false">Annuler</button>
        </div>
      </div>
    </app-modal>

    <app-modal [(isActive)]="showResendDocument" [overlayDismiss]="true" (overlayClick)="showResendDocument=false"
      [isXLarge]="true" class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Re-émission d'un document</h5>
          <div class="close" data-dismiss="modal" aria-label="Close" (click)="showResendDocument=false">
            <span aria-hidden="true">&times;</span>
          </div>
        </div>

        <div class="modal-body">
          <div class="form-group">
            <div>Nom du document:</div>
            <div><b>{{selectedCampaign?.name}}</b></div>
          </div>
          <br>

            <div class="form-group">
              <label for="mobile">Saisissez le numéro de FAX destinataire</label>
              <input type="text" name="mobile" id="mobile" class="form-control" [(ngModel)]="phoneNumber"
                autocomplete="off" autocapitalize="off" autocorrect="off">
            </div>
          </div>

          <div class="modal-footer">
            <div class="btn btn-primary" (click)='DoResend()'>Envoyer</div>
            <div class="btn btn-secondary" data-dismiss="modal" (click)="showResendDocument=false">Annuler</div>
          </div>
        </div>
      </app-modal>


