import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as R from 'ramda';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  selectedCampaign = null;

  constructor(
    private httpClient: HttpClient,
  ) { }


  AdminGetAll(adminid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/admin';
    return this.httpClient.get(url);
  }

  GetAll(clientid, status): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/user/' + clientid + '/' + status;
    return this.httpClient.get(url).pipe(
      map( (res: any) => {
        // console.log(res);
        for (const c of res) {
          c.type =  (c.asap === '1' ? 'Immédiate' : ( c.asap === '0' ? 'Planifiée le ' + c.datebegin : 'Séquencée' ));
        }
        return res;
      })
    );
  }

  Add(clientid, campaign): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid;
    return this.httpClient.post(url, campaign);
  }

  Update(clientid, campaign): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaign.id + '/update';
    return this.httpClient.put(url, campaign);
  }

  Delete(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid;
    return this.httpClient.delete(url);
  }

  AddList(clientid, campaignid, list): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/addlist';
    return this.httpClient.put(url, list);
  }

  RemoveList(clientid, campaignid, listids): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/removelist';
    return this.httpClient.put(url, listids);
  }

  GetStatus(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/status';
    return this.httpClient.get(url);
  }

  GetHistory(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/details';
    return this.httpClient.get(url);
  }

  MailReport(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/mailreport';
    return this.httpClient.get(url);
  }

  MailReportAdmin(clientid, campaignid, adminid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/mailreportadmin/' + adminid;
    return this.httpClient.get(url);
  }

  MailRcvdMessages(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/mailrcvdmessages';
    return this.httpClient.get(url);
  }

  MailRcvdMessagesAdmin(clientid, campaignid, adminid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/mailrcvdmessagesadmin/' + adminid;
    return this.httpClient.get(url);
  }

  GetRcvdMessages(clientid, campaignid): Observable<any>  {
    const url = environment.apiUrl + '/campaigns/' + clientid + '/' + campaignid + '/messages';
    return this.httpClient.get(url);
  }

  CreateDocument(document): Observable<any>  {
    const url = environment.apiUrl + '/faxes/document';
    return this.httpClient.post(url, document);
  }

  UploadDocument(docid, data): Observable<any>  {
    const url = environment.apiUrl + '/faxes/upload/' + docid;
    return this.httpClient.post(url, data);
  }

  CreateCampaign(data): Observable<any>  {
    const url = environment.apiUrl + '/faxes/campaign/' + data.account + '/start';
    return this.httpClient.post(url, data);
  }

  ResendCampaign(numbers, campid): Observable<any>  {
    const url = environment.apiUrl + '/faxes/campaign/0/resend/' + campid;
    return this.httpClient.post(url, {resendNumbers: numbers});
  }
}
