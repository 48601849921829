import { Component,OnInit,OnDestroy,Injectable } from '@angular/core';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { AnnuaireService } from 'src/app/providers/annuaire.service';

import { Router,ActivatedRoute } from '@angular/router';

import * as R from 'ramda';
import moment from 'moment';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { IUser } from 'src/app/interfaces/user';
import { CampaignService } from 'src/app/providers/campaign.service';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { ContactListService } from 'src/app/providers/contact-list.service';


import { NgbDateAdapter,NgbDateStruct,NgbDateNativeAdapter,NgbDatepickerI18n,NgbTimeAdapter,NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, TranslationWidth } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClientSelectComponent } from '../client-select/client-select.component';


/**
 * Example of a String Time adapter
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string): NgbTimeStruct {
    if (!value) {
      return null;
    }
    const time = moment(value);
    return {
      hour: time.hours(),
      minute: time.minutes(),
      second: time.seconds()
    };
  }

  toModel(time: NgbTimeStruct): string {
    if (!time) {
      return null;
    }
    const now = moment();
    now.hour(time.hour);
    now.minute(time.minute);
    now.second(0);
    return now.format('YYYY-MM-DD HH:mm:ss');
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }
}

export class NgbdTimepickerAdapter {
  time: '08:00:00';
}

const I18N_VALUES = {
  fr: {
    weekdays: [ 'Lu','Ma','Me','Je','Ve','Sa','Di' ],
    months: [ 'Jan','Fév','Mar','Avr','Mai','Juin','Juil','Aou','Sep','Oct','Nov','Déc' ],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  // tslint:disable-next-line:variable-name
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[ this._i18n.language ].weekdays[ weekday - 1 ];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[ this._i18n.language ].months[ month - 1 ];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.getWeekdayShortName(weekday);
  }

}


@Component({
    selector: 'app-campaign',
    templateUrl: './campaign.component.html',
    styleUrls: ['./campaign.component.scss'],
    providers: [
        I18n,
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }
    ],
    standalone: true,
    imports: [NgxSpinnerModule, ClientSelectComponent, FormsModule, NgbInputDatepicker, NgClass]
})
export class CampaignComponent implements OnInit,OnDestroy {
  client: IUser;
  pdfFileEmpty = {
    name: '',
    progress: '',
    data: '',
    size: 0,
    pagecount: 0,
    quality: 'high',
    envoi: '1',
    date: '',
    time: '',
    faxnumbers: '',
    filter: '',
    cfilter: '',
    page: 1,
    faxline: null,
    option_entete: '0',
    message_entete: '',
    destname: '',
    destcompany: ''
  };
  pdfFile = null;

  activeTab = 0;
  clientSubscribe = null;

  faxlines = [];

  lists = [];
  fields = [];
  totalContact: number;

  listDefault = { id: 0,visible: true,actif: 0,contacts: [] };
  list = R.clone(this.listDefault);
  filter = '';
  showListFilterModal = false;
  fieldToAdd = '';

  annuaires = [];
  contactlists = [];

  dataLoading = false;
  dateChanged = false;

  period = {
    startdate: new Date(moment().format('YYYY-MM-01')),
    stopdate: new Date(moment().format('YYYY-MM-') + moment().endOf('month').format('DD'))
  };
  origPeriod = R.clone(this.period);

  selectedPeriod = {
    startdate: moment(this.period.startdate).format('YYYY-MM-DD'),
    stopdate: moment(this.period.stopdate).format('YYYY-MM-DD'),
    admin: this.authService.adminId,
  };


  constructor(
    public pagingService: PagingService<any>,
    private userService: UserService,
    private annuaireService: AnnuaireService,
    private campaignService: CampaignService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private authService: AuthenticationService
  ) {
    console.log('campaign ctor');

    this.activeTab = 0;

    const action = this.activatedRoute.snapshot.params.action;

    this.clientSubscribe = this.userService.selectedUser$.subscribe(user => {
      if (user) {
        this.client = R.clone(user);
        this.annuaireService.GetAll(this.client.id)
          .subscribe((annuaires: any[]) => {
            this.annuaires = annuaires;
          });
        this.userService.GetFaxlines(this.client.id).subscribe((lines: any[]) => {
          this.faxlines = lines;
        });
      }
    });

    this.pdfFile = R.clone(this.pdfFileEmpty);
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (!this.client) {
        this.toastr.warning('Sélectionner un client en premier', '');
        this.router.navigate([ '/' ]);
      }
    },1000);
  }

  ngOnDestroy() {
    if (this.clientSubscribe) {
      this.clientSubscribe.unsubscribe();
    }
  }

  selectTab(tab) {
    this.activeTab = tab;
  }

  checkDate() {
    this.dateChanged = this.origPeriod.startdate !== this.period.startdate ||
      this.origPeriod.stopdate !== this.period.stopdate;
  }


  onFilesAdded(files: File[]) {
    console.log(files);
    this.spinner.show();

    for (const file of files) {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const content = event.target.result;
        const data = { name: file.name,size: file.size,data: content };
        // this content string could be used directly as an image source
        // or be uploaded to a webserver via HTTP request.

        this.pdfFile.name = data.name;
        this.pdfFile.data = data.data;
        this.pdfFile.size = Math.floor(data.size / 1024 * 10) / 10;
        this.pdfFile.pagecount = 0;

        try {
          this.pdfFile.pagecount = this.pdfFile.data.match(
            /\/Type[\s]*\/Page[^s]/g
          ).length;
        } catch (e) {
          this.pdfFile.pagecount = 0;
        }
        setTimeout(() => {
          this.pdfFile.progress = '';
        }, 1000);
        this.spinner.hide();
      };

      // reader.onprogress = (data: any) => {
      //   console.log('onprogress=', data);
      //   if (data.lengthComputable) {
      //     this.pdfFile.size = Math.floor(data.total / 1024 * 10) / 10;
      //     const progress = Math.ceil((data.loaded / data.total) * 100);
      //     this.pdfFile.progress = progress + '%';
      //   }
      // };

      // for PDF use binary format
      reader.readAsBinaryString(file);
    }
  }

  // createCampaign(priority, name) {
  //   const now = new Date(Date.now());
  // }

  AddCampaignCancel() {
    this.router.navigate([ '/' ]);
  }

  addDest(contact) {
    if (this.pdfFile.faxnumbers !== undefined && this.pdfFile.faxnumbers.indexOf(contact.faxnumber) !== -1) {
      return;
    }

    if (this.pdfFile.faxnumbers === undefined || this.pdfFile.faxnumbers === '') {
      this.pdfFile.faxnumbers = contact.faxnumber;
      this.pdfFile.destcompany = contact.company;
      this.pdfFile.destname = contact.lastname + ' ' + contact.firstname;
    } else {
      this.pdfFile.faxnumbers += ';' + contact.faxnumber;
      this.pdfFile.destcompany = '';
      this.pdfFile.destname = '';
    }
  }
  contactListSelect(contact) {

  }

  yyyymmdd(date) {
    const yyyy = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString(); // getMonth() is
    // zero-based
    const dd = date.getDate().toString();
    return (
      yyyy + '-' + (mm[ 1 ] ? mm : '0' + mm[ 0 ]) + '-' + (dd[ 1 ] ? dd : '0' + dd[ 0 ])
    ); // padding
  }

  hhmm(date) {
    if (typeof date === 'string') {
      if (date.length === 8) {
        return date;
      } else {
        return date.substr(11,8);
      }
    }
    const hh = date.getHours().toString();
    const mm = date.getMinutes().toString();
    return (
      (hh[ 1 ] ? hh : '0' + hh[ 0 ]) + ':' + (mm[ 1 ] ? mm : '0' + mm[ 0 ]) + ':00'
    ); // padding
  }


  sendDocument() {
    console.log('clientid=' + this.client.id);
    console.log('document=' + this.pdfFile.name);
    console.log('size=' + this.pdfFile.size);
    console.log('faxlineid=' + this.pdfFile.faxline);

    if (this.pdfFile.name === '') {
      this.toastr.error('Vous devez séléctionner un document !!', 'Erreur', { timeOut: 0 });
      return;
    }
    if (this.pdfFile.size === 0) {
      this.toastr.error('Votre document est vide !!', 'Erreur', { timeOut: 0 });
      return;
    }
    if (this.pdfFile.size >= 5 * 1024 * 1024 ) {
      this.toastr.error('Votre document est trop gros (max 6Mo). Merci de le découper ou de réduire son poids !!', 'Erreur', { timeOut: 0 });
      return;
    }
    if (this.pdfFile.faxline === undefined) {
      this.toastr.error('Vous devez choisir une ligne pour l\'envoi !!', 'Erreur', { timeOut: 0 });
      return;
    }

    let faxnumber = this.pdfFile.faxline.faxnumber;
    if (this.pdfFile.faxline.ligneportee !== '') { faxnumber = this.pdfFile.faxline.ligneportee; }
    const header = {
      from: {
        name: this.pdfFile.faxline.name,
        company: this.client.company,
        faxnumber,
        telephone: this.client.phone1,
        email: this.client.username
      },
      dest:
      {
        name: this.pdfFile.destname,
        company: this.pdfFile.destcompany,
        faxnumber: this.pdfFile.faxnumbers
      },
      subject: '',
      message: (this.pdfFile.option_entete === 1 ? this.pdfFile.message_entete : ''),
      name: this.pdfFile.name
    };

    const document = {
      account: this.pdfFile.faxline.account,
      faxlineid: this.pdfFile.faxline.id,
      clientid: this.client.id,
      name: this.pdfFile.name,
      details: '',
      size: this.pdfFile.size,
      data: this.pdfFile.data,
      pages: this.pdfFile.pagecount,
      header
    };
    this.spinner.show();

    this.toastr.success('Chargement du document ...');
    this.campaignService.CreateDocument(document).subscribe((response) => {
      if (response.success) {

        const thedoc = response.document;

        this.toastr.success('Le document a été chargé - préparation du document en cours');

        // page number may have change if entete added
        if (response.document && response.document.pages) {
          thedoc.pages = response.document.pages;
        }

        const data = {
          account: this.pdfFile.faxline.account,
          filepath: thedoc.filepath
        };

        this.campaignService.UploadDocument(thedoc.id,data).subscribe((uplopadResponse) => {
          if (uplopadResponse.success) {
            this.toastr.success('Le document est prét');

            const fulldata = {
              account: this.pdfFile.faxline.account,
              faxnumber: this.pdfFile.faxline.faxnumbers,
              faxlineid: this.pdfFile.faxline.id,
              header,
              document: thedoc,
              filename: thedoc.fullname,
              pages: thedoc.pages,
              phoneNumber: this.pdfFile.faxnumbers,
              sendDate: (this.pdfFile.envoi === 1 ? '' : moment(this.pdfFile.date).format('YYYY-MM-DD') + ' ' + this.pdfFile.time)
            };

            this.campaignService.CreateCampaign(fulldata).subscribe((campaignResponse) => {
              this.spinner.hide();
              if (campaignResponse.success) {
                this.toastr.success('Le document va être envoyé');
                // all went right : jump to Fax send tab
                this.router.navigate(['/fax/envoyes']);
              } else {
                this.toastr.error(campaignResponse.message, 'Erreur', { timeOut: 0 });
              }
            });

          } else {
            this.spinner.hide();
            this.toastr.error(uplopadResponse.message, 'Erreur', { timeOut: 0 });
          }
        });
      } else {
        this.spinner.hide();
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
    });


  }
}
