<div class="client-view p-md-4">

  <ngx-spinner bdOpacity=0.2 bdColor="rgba(51,51,51,0)" size="medium" color="#515151" type="ball-pulse-sync"
    [fullScreen]="false">
  </ngx-spinner>

  <div class="card">
    <div class="card-body">

      <span us-spinner="{radius:30, width:8, length: 16, position:'fixed', top:'30%'}" spinner-key="spinner-3"></span>

      @if (faxpacks==null && initialized) {
        <div>
          <div class="alert alert-danger">Les offres FAX n'ont pas été chargés correctement, merci de contacter votre
          administrateur !!</div>
        </div>
      }

      <div class="row">

        <div class="col-md-6">
          Votre contact:
          <ul class="list-unstyled" style="margin-left:20px; font-size: 16px">
            <li style="font-size: 18px; font-weight: 500;"><i
            class="fa fa-envelope-o"></i>&nbsp;&nbsp;{{client?.adminusername}}</li>
            <li><i class="fa fa-phone"></i>&nbsp;&nbsp;{{client?.adminphone}}</li>
          </ul>
        </div>

        <div class="col-md-6">
          <h3 class="pull-right">Vous avez un code promo ?</h3>
          <div class="row pull-right m-1">
            <input type="text" class="input-small" [(ngModel)]="codepromo.tentative" placeholder="code promo"
              autocomplete="off" autocapitalize="off" autocorrect="off" (keyup.enter)="validateCodePromo()">
            <div class="btn btn-primary" (click)="validateCodePromo()">
              Valider
            </div>
          </div>
        </div>
      </div>

      <br>
        <br>


          @if (activeTab === 'premium') {
            <div class="tab-pane fade show active" id="premium" role="tabpanel" aria-labelledby="premium-tab"
              >
              <h4 class="txt18 bold">Prix des offres FAX</h4>
              <div class="text-center">
                <form name="form" role="form" autocomplete="off" autocapitalize="off" autocorrect="off" form-autofill-fix>
                  <!-- {{smspacks | json}} -->
                  <table class="table table-bordered footable tablet no-paging footable-loaded" id="tableSender"
                    style="width: 100%">
                    <thead>
                      <tr>
                        <th>Nombre de pages</th>
                        <th>Prix unitaire (HT)</th>
                        <th>Prix total (HT)</th>
                        <th>Prix total (TTC)</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      @for (p of faxpacks?.premium; track p) {
                        <tr>
                          <td>
                            @if (p.custom==1) {
                              <div>
                                {{p.productname}}<br>
                                <input type="text" name="input1" class="input-small" name="custom" [(ngModel)]="p.qty"
                                  (ngModelChange)="recomputePrice(p.qty)" #pQty (click)="pQty.select()">
                                &nbsp;pages&nbsp;
                              </div>
                            }
                            @if (p.custom==0) {
                              <div>
                                {{p.productname}}
                              </div>
                            }
                          </td>
                          <td>
                            <div><b>{{p.unitprice |number: '0.3-3'}}</b></div>
                          </td>
                          <td>
                            {{p.totalht | number: '1.2-2' }}
                          </td>
                          <td>
                            {{p.totalttc | number: '1.2-2'}}
                          </td>
                          <td>
                            <div class="btn btn-info btn-sm" (click)="selectPack(p)"><i
                            class="fa fa-cart"></i>&nbsp;&nbsp;Sélectionner
                          </div>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        }

      </div>
    </div>
  </div>
