import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IUser, User } from '../interfaces/user';
import { AuthenticationService } from './authentication.service';
import { map, tap } from 'rxjs/operators';
import { IConso } from '../interfaces/consos';
import { ContactListUploadComponent } from '../components/contact-list-upload/contact-list-upload.component';
import { PagingService } from './paging.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  pagingService = new PagingService<User>();

  emptyUser: User = new User();
  // selectedUser: IUser = this.emptyUser;
  selectedUser$ = new BehaviorSubject<IUser>(null);

  consoPeriod$ = new BehaviorSubject<any>(null);
  consoAdmin$ = new BehaviorSubject<any>(null);
  consoPeriodAdmin = { totalPremium: 0, totalLowcost: 0, moyLowCost: 0, moyPremium: 0 };

  postpaid$ = new BehaviorSubject<any>(null);
  forfait$ = new BehaviorSubject<any>(null);

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
  ) {
    this.getCurrentClient();
  }

  getSelectedUser() {
    return this.selectedUser$.getValue();
  }

  clearSelectedUser() {
    this.selectedUser$.next(null)
  }

  getCurrentClient() {
    console.log('user service ctor');
    if (!this.authService.isAdmin) {
      // load user information
      const loggedUser = JSON.parse(sessionStorage.getItem('currentUser'));
      this.GetById(loggedUser.userId).subscribe((user: IUser) => {
        // this.selectedUser = user;
        this.selectedUser$.next(user);
      });
    }
  }

  GetCreditsHistory(clientid, period): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/clients/credithistory/' + clientid, period).pipe(
      tap((res: any) => {
        if (res.success) {
        this.consoPeriod$.next ( { totalFaxCredits: res });
        }
        res.history.sort(this.sortCreditsOnId);
      }),
      map((res: any) =>  res.history )
    );
  }
  GetCreditsHistoryAll(): Observable<any> {
    return this.consoPeriod$;
  }

  sortCreditsOnId(a, b) {
    // sort on id DESC
    if (a.id < b .id) {
      return 1;
    } else if (a.id > b .id) {
        return -1;
    } else {
        return 0;
    }
  }
  GetCreditsAdminHistory(period): Observable<any> {
    console.log('GetCreditsAdminHistory');
    return this.httpClient.post(environment.apiUrl + '/credits/adminhistory', period).pipe(
      tap( (res: any) => {
        console.log(res);
        if (res.success) {
          this.consoPeriod$.next ( {
            totalFaxCredits: res.totalFaxCredits,
          });
          res.credits.forEach(c => {
            try {
              c.fullname =  c.username + ' / ' + c.company + ' - ' + c.name;
              delete c.username;
              delete c.company;
              delete c.name;
              c.creditid = parseInt(c.creditid, 10);
            } catch (e) {
              console.log(e);
              c.fullname = '??';
            }
          });
          res.credits.sort(this.sortCreditsOnId);
          this.consoAdmin$.next(res.conso);
          }
        }),
      map((res: any) =>  res.credits )
    );
  }

  GetConsoAdmin() {
    return this.consoAdmin$;
  }


  GetCreditsRecap(adminid): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/credits/adminrecap/' + adminid, adminid);
  }

  GetCreditsPostpaid(adminid): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/credits/adminpostpaid/' + adminid, adminid).pipe(
      tap((res: any) => {
        if (res.success) {
          this.postpaid$.next( { totalFaxCredits: res.total});
        }
      }),
      map((res: any) =>  res.credits )
    );
  }
  GetPostPaidCounter(): Observable<any> {
    return this.postpaid$;
  }


  AddAdmin(admin): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/admins/add', admin);
  }

  UpdateAdmin(admin): Observable<any> {
    return this.httpClient.put(environment.apiUrl + '/admins/update/' + admin.id, admin);
  }

  DeleteAdmin(adminid): Observable<any> {
    return this.httpClient.delete(environment.apiUrl + '/admins/' + adminid);
  }

  GetAdmins(adminid, role): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/admins/getall', { id: adminid, role });
  }

  GetAll(adminid): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/clients/getall/' + adminid);
  }

  GetMyClients(id): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/clients');
  }

  GetById(id) {
    return this.httpClient.get(environment.apiUrl + '/clients/' + id).pipe(
      tap( user => this.selectedUser$.next(user as IUser))
    );
  }

  GetByUsername(username) {
    return this.httpClient.get(environment.apiUrl + '/clients/username/' + username);
  }

  Create(user) {
    return this.httpClient.post(environment.apiUrl + '/clients/create', user);
  }

  Update(user) {
    return this.httpClient.put(environment.apiUrl + '/clients/' + user.id, user);
  }

  changePassword(user, password) {
    return this.httpClient.put(environment.apiUrl + '/clients/changepassword/' + user.id, password);
  }

  Delete(user) {
    return this.httpClient.put(environment.apiUrl + '/clients/setdeleted/' + user.id, user);
  }

  Disable(user, disabled) {
    return this.httpClient.put(environment.apiUrl + '/clients/setdisabled/' + user.id + '/' + disabled, user);
  }

  DeleteID(userid) {
    return this.httpClient.delete(environment.apiUrl + '/clients/' + userid);
  }


  GetConso(userid, periodlen) {
    return this.httpClient.get(environment.apiUrl + '/clients/conso/' + userid + '/' + periodlen);
  }

  GetFaxlines(userid) {
    return this.httpClient.get(environment.apiUrl + '/faxlines/' + userid);
    // return this.httpClient.get(environment.apiUrl + '/faxlines/' + userid).pipe(tap((lines: any[]) => {
    //   lines.forEach(l => {
    //     l.authemail = l.authemail.replace(/;/g, ' ');
    //     l.notifemail = l.notifemail.replace(/;/g, ' ');
    //   });
    // }));
  }

  AddFaxline(userid, faxline) {
    return this.httpClient.post(environment.apiUrl + '/faxlines/' + userid, faxline);
  }

  UpdateFaxline(faxline) {
    return this.httpClient.put(environment.apiUrl + '/faxlines/' + faxline.id, faxline);
  }

  DeleteFaxline(userid, faxlineid) {
    return this.httpClient.delete(environment.apiUrl + '/faxlines/' + faxlineid);
  }

  DeleteUser(userid) {
    return this.httpClient.delete(environment.apiUrl + '/faxusers/' + userid);
  }

  UpdateUser(user) {
    return this.httpClient.put(environment.apiUrl + '/faxusers/' + user.id, { banned: user.banned });
  }


  // -------------------------------------------
  //  for all
  PasswordRequest(username) {
    return this.httpClient.get(environment.apiUrl + '/clients/passwordrequest/' + username);
  }

  VerifyChecksum(username, checksum) {
    return this.httpClient.get(environment.apiUrl + '/clients/verifychecksum/' + username + '/' + checksum);
  }

  Activate(username, checksum) {
    return this.httpClient.get(environment.apiUrl + '/clients/activate/' + username + '/' + checksum);
  }

  ChangeUsernamePassword(username, checksum, password) {
    return this.httpClient.get(environment.apiUrl + '/clients/changepassword/' + username + '/' + checksum + '/' + password);
  }

  Logout() {
    return this.httpClient.get(environment.apiUrl + '/logout');
  }

  adminLogout() {
    return this.httpClient.get(environment.apiUrl + '/adminlogout');
  }


  // -------------------------------------------
  //  add credits  : admin only

  AddCredits(clientid, quantity) {
    return this.httpClient.post(environment.apiUrl + '/clients/credits/' + clientid, { quantity });
  }


}
