import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactListService } from '../../providers/contact-list.service';

import moment from 'moment';
import * as XLSX from 'xlsx';
import { Papa } from 'ngx-papaparse';
import { ToastrService } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { ModalComponent } from '../../modals/modal.component';
import { DialogComponent } from '../../dialog/dialog.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-import-file',
    templateUrl: './import-file.component.html',
    styleUrls: ['./import-file.component.scss'],
    standalone: true,
    imports: [NgClass, DialogComponent, ModalComponent, FormsModule]
})
export class ImportFileComponent implements OnInit {

  filename = '';
  mobileField: any;
  listid = 0;
  emptyParam = { name: '', fields: [] };

  selectedSheet: any;
  output = [];
  mobile = '';
  listName = '';
  showImportList = false;

  @Input() clientid = 0;
  @Input() isSmall = false;
  @Output() closeImport: EventEmitter<any> = new EventEmitter<any>();

  dialog = {
    show: false,
    isLarge: false,
    title: '',
    body: '',
    action: '',
    type: '',
  };

  constructor(
    private spinner: NgxSpinnerService,
    private contactListService: ContactListService,
    private papa: Papa,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
  }

  onFilesAdded(files: File[]) {
    console.log(files);

    if (files && files.length) {
      for (const file of files) {
        this.handleSelect(file);
        break; // only 1st file
      }
    }
  }


  selectSheet(sheet) {
    this.selectedSheet = sheet;
  }


  importList(output) {
    const now = new Date(Date.now());
    this.listName = this.filename.split('.').shift() + ' ' + moment(now).format('YYYY-MM-DD');
    this.output = output;
    this.selectedSheet = output[0];
    this.mobile = '';

    this.showImportList = true;
  }

  confirmListImport() {
    this.spinner.show();

    this.mobileField = { name: '', position: 0 };

    this.output.forEach((sheet) => {
      console.log('name=' + sheet.name);
      if (sheet.name === this.selectedSheet.name) {
        // store mobileField name and position
        const position = -1;
        this.mobileField = { name: this.mobile, position: -1 };
        for (let index = 0; index < sheet.fields.length; index++) {
          if (sheet.fields[index] === this.mobile) {
            this.mobileField.position = index;
            break;
          }
        }

        this.contactListService.Add(
          this.clientid,
          { name: this.listName,
            mobile: this.mobile,
            fields: sheet.fields }
        ).subscribe((response) => {
            if (response.success) {
              this.listid = response.id;

              // now import all contact
              this.contactListService.ContactAdd(
                this.clientid,
                this.listid,
                { mobileField: this.mobileField,
                  data: sheet.data
                }
                ).subscribe((response2: any) => {
                  if (response2.success) {

                    this.dialog = {
                      show: true,
                      isLarge: true,
                      title: 'Liste importée',
                      body: '<br>La liste a été créée avec l\'identifiant: ' + this.listid + '<br><br>' +
                        response2.count + ' contacts ajouté' + (response2.count > 1 ? 's' : '') + '<br>' +
                        response2.duplicated + ' dupliqué' + (response2.duplicated > 1 ? 's' : '') + '<br>' +
                        response2.invalid + ' invalide' + (response2.invalid > 1 ? 's' : '' ),
                      action: 'OK',
                      type: 'info',
                    };

                  } else {
                    this.dialog = {
                      show: true,
                      isLarge: true,
                      title: 'L\'importation de la liste a échouée',
                      body: '<br>La liste a été créée avec l\'identifiant: ' + this.listid + '<br><br>'  +
                        'Erreur lors de l\'ajout des contacts:<br>' + response2.message,
                      action: 'Fermer',
                      type: 'error',
                    };
                    this.closeImport.emit(0);
                  }
                  this.spinner.hide();
                });
            } else {
              this.dialog = {
                show: true,
                isLarge: true,
                title: 'La création de la liste a échouée',
                body: response.message,
                action: 'Fermer',
                type: 'error',
              };
              this.spinner.hide();
            }
        });
      } // good sheet name
    });
    this.showImportList = false;
  }

  removeAccents(str) {
    // verifies if the String has accents and replace them
    if (str.search(/[\xC0-\xFF]/g) > -1) {
      str = str
        .replace(/[\xC0-\xC5]/g, 'A')
        .replace(/[\xC6]/g, 'AE')
        .replace(/[\xC7]/g, 'C')
        .replace(/[\xC8-\xCB]/g, 'E')
        .replace(/[\xCC-\xCF]/g, 'I')
        .replace(/[\xD0]/g, 'D')
        .replace(/[\xD1]/g, 'N')
        .replace(/[\xD2-\xD6\xD8]/g, 'O')
        .replace(/[\xD9-\xDC]/g, 'U')
        .replace(/[\xDD]/g, 'Y')
        .replace(/[\xDE]/g, 'P')
        .replace(/[\xE0-\xE5]/g, 'a')
        .replace(/[\xE6]/g, 'ae')
        .replace(/[\xE7]/g, 'c')
        .replace(/[\xE8-\xEB]/g, 'e')
        .replace(/[\xEC-\xEF]/g, 'i')
        .replace(/[\xF1]/g, 'n')
        .replace(/[\xF2-\xF6\xF8]/g, 'o')
        .replace(/[\xF9-\xFC]/g, 'u')
        .replace(/[\xFE]/g, 'p')
        .replace(/[\xFD\xFF]/g, 'y');
    }
    return str;
  }

  to_json(workbook) {

    const result = [];
    let id = 0;
    workbook.SheetNames.forEach((sheetName) => {

      console.log('to_json');

      const cells = workbook.Sheets[sheetName];
      for (const key in cells) {
        if (cells.hasOwnProperty(key)) {
          const cell = cells[key];
          if (cell.t === 'd') {
            delete cell.w;
            cell.z = 'DD/MM/YYYY';
            XLSX.utils.format_cell(cell);
            // Overwrite value with formated one :<
            cell.v = cell.w;
          }
        }
      }

      const json: Array<object> = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { defval: ''});

      const fields = [];
      const data = [];
      if (json.length > 0) {
        for (const key in json[0]) {
          if (json[0].hasOwnProperty(key)) {
            fields.push(this.removeAccents(key.toUpperCase()));
          }
        }
        json.forEach((row) => {
          const rowdata = [];
          for (const key in row) {
            if (row.hasOwnProperty(key)) {
              rowdata.push(row[key]);
            }
          }
          data.push(rowdata);
        });
      }
      result.push({ id, name: sheetName, fields, data });
      id++;
    });
    return result;
  }

  onRead(workbook) {
    /* DO SOMETHING WITH workbook HERE */
    console.log(workbook);

    const result = this.to_json(workbook);
    this.importList(result);
  }

  onCSV(rows) {

    const result = [];
    let fields = [];
    const data = [];
    if (rows.length > 0) {
      fields = rows[0];
      let isHeaderOk = true;
      fields.forEach((f) => {
        if (/[a-z]/.test(f)) {
          isHeaderOk = false;
        }
      });
      if (isHeaderOk) {
        rows = rows.slice(1);
        // check last row is empty ?
        const last = rows.length - 1;
        if (rows[last].length < fields.length) {
          rows = rows.slice(0, -1);
        } else if (rows[last][0] === '') {
          rows = rows.slice(0, -1);
        }

        result.push({ id: 0, name: 'csv', fields, data: rows });
        this.importList(result);
      } else {
        alert('Votre ligne d\'en tête doit être en majuscule! Corrigez votre fichier SVP\n' + JSON.stringify(fields));
      }
    } else {
      alert('Votre fichier est vide !');
    }
  }

  onError(e) {
    /* DO SOMETHING WHEN ERROR IS THROWN */
    console.log(e);
  }

  handleSelect(file) {

    if (file === null) { return; }

    const reader = new FileReader();
    const name = file.name;
    this.filename = name;
    reader.onload = (event: any) => {
      const content = event.target.result;
      const data = { name: file.name, size: file.size, data: content};

      const fileext = file.name.split('.').pop();
      if (fileext === 'csv') {
        const result = this.papa.parse(data.data);
        result.errors.forEach((e) => {
          if (e.code === 'UndetectableDelimiter') {
            const rows = data.data.split('\n');
            result.data = [];
            rows.forEach((row) => {
              result.data.push([row.replace('\r', '')]);
            });
          }
        });
        this.onCSV(result.data);
      } else if (fileext === 'xls' || fileext === 'xlsx') {
        /* if binary string, read with type 'binary' */
        try {
          const workbook = XLSX.read(data.data, { type: 'binary', cellDates: true});

          this.onRead(workbook);

        } catch (error) {
          this.onError(error);
        }
      } else {
        this.toastr.error('Uniquement les fichier CSV, XLS ou XLSX !');
      }
    };
    reader.readAsBinaryString(file);
  }

  // upload on file select or drop
  upload(file, clientid) {
    return new Promise((resolve, reject) => {
      this.clientid = clientid;
      this.handleSelect(file);
    });
  }

  // for multiple files:
  uploadFiles(files, clientid) {
  }

  closeDialog() {
    this.dialog.show = false;
    console.log('closeDialog import-file');
    this.closeImport.emit(this.listid);
  }

}
