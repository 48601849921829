export interface IAnnuaire {
  id: number;
  customerid: number;
  company: string;
  service: string;
  lastname: string;
  firstname: string;
  telnumber: string;
  faxnumber: string;
  email: string;
  deleted: number;
  listid: number;
  list: string;
}
export class Annuaire implements IAnnuaire {
  id: number;
  customerid: number;
  company: string;
  service: string;
  lastname: string;
  firstname: string;
  telnumber: string;
  faxnumber: string;
  email: string;
  deleted: number;
  listid: number;
  list: string;
  constructor() {
    this.id = 0;
  }
}
